import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import URL_UTILITY from '@/app/utility/url.utility';
import { format } from 'date-fns';
import DatePicker from 'vue2-datepicker';
import allDocuments from '../../../../components/shared/allDocuments';
import uploadExcel from '@/app/components/shared/uploadExcel';
import ApprovalHistory from '@/app/components/onlineApproval/internalWorkFlow/history/approvalHistory';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import ExcelJS from 'exceljs';
export default {
  name: 'invoiceApprovalDetails',
  watch: {
    currentPageExpense: function() {
      this.getExpenseMonth();
    },
    perPageExpense: function() {
      this.currentPageExpense = 1;
      this.getExpenseMonth();
    },
    currentPageTdsSection: function() {
      this.getTdsSection();
    },
    perPageTdsSection: function() {
      this.currentPageTdsSection = 1;
      this.getTdsSection();
    },
    currentPageTdsRate: function() {
      this.getTdsRate();
    },
    perPageTdsRate: function() {
      this.currentPageTdsRate = 1;
      this.getTdsRate();
    },
    currentPageGrnMapping: function() {
      this.getGrnReceiptMappingData();
    },
    perPageGrnMapping: function() {
      this.currentPageGrnMapping = 1;
      this.getGrnReceiptMappingData();
    },
    tags() {
      this.adjustTagsWidth();
    },
    currentPageCostCentre: function() {
      this.getCostCentreDetails();
    },
    perPageCostCentre: function() {
      this.currentPageCostCentre = 1;
      this.getCostCentreDetails();
    },
    currentPageDistribution: function() {
      this.getDistributionDetails();
    },
    perPageDistribution: function() {
      this.currentPageDistribution = 1;
      this.getDistributionDetails();
    }
  },
  props: [
    'invoiceRowData',
    'wfMenuId',
    'userId',
    'eventId',
    'invoiceApprovalDetails',
    'wfType'
  ],
  components: {
    allDocuments,
    ApprovalHistory,
    DatePicker,
    UploadDocument,
    uploadExcel
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      previewPage: 1,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      perPageGrnMapping: commonHelper.perPageRecord,
      pageOptionsGrnMapping: commonHelper.getPageOption(),
      totalRowsGrnMapping: null,
      currentPageGrnMapping: 1,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      loader: false,
      editMode: false,
      vendorInvId: null,
      menuId: null,
      wfHdrId: null,
      wfDtlId: null,
      approvalStatusKey: null,
      headerDetails: {
        po_header_id: null,
        po_num: null,
        po_status: null,
        inv_num: null,
        inv_amt: null,
        show_inv_amt: null,
        first_party_gstn: null,
        third_party_gstn: null,
        currency: null,
        inv_agreement: null,
        inv_status: null,
        liability_account: null,
        dif_amount: null,
        payment_term: null,
        inv_date: null,
        due_date: null,
        uploaded_document: null,
        vendor_inv_id: null,
        le_id: null,
        le_name: null,
        vendor_id: null,
        vendor_name: null,
        ou_id: null,
        vendor_site_id: null,
        approval_status: null,
        approval_status_key: null,
        wf_inprocess_hdr_id: null,
        wf_inprocess_dtl_id: null,
        seq_order: null,
        vendor_site_name: null,
        exchange_rate: null,
        actual_gr_date: null,
        gl_date: null,
        period_name: null,
        material_received_by: null,
        receipt_num: null,
        buyer_name: null,
        buyer_email_id: null,
        buyer_emp_id: null,
        po_creation_date: null,
        erp_le_id: null,
        remarks: null,
        grn_attach_flag: false,
        manual_invoice: null
      },
      invoiceLineData: [
        {
          period_name: null,
          tds_flag: false,
          tds_section_code: null,
          tds_section_meaning: null,
          tax_rate_id: null,
          tax_rate_name: null,
          tax_non_recoverable: false
        }
      ],
      invoiceLineFields: [
        {
          key: 'po_number',
          label: 'PO Number',
          class: 'col-width-fix'
        },
        {
          key: 'po_line_num',
          label: 'PO Line No.',
          class: 'col-width-fix text-center'
        },
        {
          key: 'po_release_num',
          label: 'PO Release',
          class: 'col-width-fix text-center'
        },
        {
          key: 'po_shipment_line_num',
          label: 'Shipment Line No.',
          class: 'text-center'
        },
        {
          key: 'item_code',
          class: 'col-width-fix'
        },
        {
          key: 'item_description',
          label: 'Description'
        },
        {
          key: 'po_price',
          label: 'PO Price',
          class: 'col-width-fix'
        },
        {
          key: 'uom',
          label: 'UOM',
          class: 'col-width-fix'
        },
        {
          key: 'deliver_qty',
          label: 'Qty. Being Delivered',
          class: 'qty-width-fix'
        },
        {
          key: 'hsn_sac',
          label: 'HSN/SAC',
          class: 'col-width-fix'
        },
        {
          key: 'shp_to_loc',
          label: 'Ship To Loc'
        },
        {
          key: 'release_line_from_date',
          label: 'Release Start Date'
        },
        {
          key: 'release_line_to_date',
          label: 'Release End Date'
        },
        {
          key: 'item_type',
          class: 'col-width-fix'
        },
        {
          key: 'tax_category_name',
          label: 'Tax Category'
        },
        { key: 'taxable_amount', class: 'tax-amt-fix' },
        {
          key: 'tax_amount',
          class: 'text-fix-po'
        },
        {
          key: 'tax_details',
          label: 'Tax',
          stickyColumn: true
        },
        {
          key: 'status_code',
          label: 'GRN Status',
          class: 'col-width-fix'
        },
        {
          key: 'status_msg',
          label: 'Error Message'
        }
      ],
      previewTaxData: [],
      previewTaxFields: [
        { key: 'tax_type', class: 'tax-type-fix' },
        { key: 'taxable_amount', class: 'col-width-acc' },
        { key: 'tax_rate', class: 'tax-type-fix' },
        { key: 'amount', label: 'Tax Amount', class: 'tax-type-fix' },
        {
          key: 'tax_category_name',
          label: 'Tax Category',
          class: 'tax-cat-fix'
        },
        { key: 'rcm_flag', label: 'RCM', stickyColumn: true }
      ],
      workflowId: { menuId: null, userId: null, eventId: null },
      showPreviewTaxModal: false,
      documentData: [],
      documentFields: [
        {
          key: 'original_file_name',
          label: 'File Name'
        },
        {
          key: 'file_name',
          label: 'Preview',
          stickyColumn: true
        },
        {
          key: 'preview_file',
          label: 'New Tab',
          stickyColumn: true
        },
        {
          key: 'delete',
          stickyColumn: true
        }
      ],
      showDocumentModal: false,
      rejectedReason: null,
      showRejectModal: false,
      showApproveModal: false,
      approvalReason: null,
      openKmAuth: null,
      uploadedFileName: null,
      showOpenModal: false,
      docDtlId: null,
      authToken: null,
      downloadDocUrl: URL_UTILITY.getOpenKmDocDeleteUrl,
      showInvoiceCommonModal: false,
      modalType: null,
      expenseData: [],
      expenseFields: [
        {
          key: 'period_name'
        }
      ],
      expenseMonthSearch: null,
      tdsSectionSearch: null,
      tdsRateSearch: null,
      perPageExpense: commonHelper.perPageRecord,
      totalRowsExpense: null,
      currentPageExpense: 1,
      tdsSectionData: [],
      tdsSectionFields: [
        {
          key: 'lookup_code',
          label: 'Section'
        },
        {
          key: 'meaning',
          label: 'Description'
        }
      ],
      perPageTdsSection: commonHelper.perPageRecord,
      totalRowsTdsSection: null,
      currentPageTdsSection: 1,
      tdsRateData: [],
      tdsRateFields: [
        { key: 'tax_rate_id' },
        { key: 'tax_rate_name', label: 'Tax Rate' }
      ],
      perPageTdsRate: commonHelper.perPageRecord,
      totalRowsTdsRate: null,
      currentPageTdsRate: 1,
      specIndex: null,
      isComDetailsSaved: false,
      isFinDetailsSaved: false,
      expenseType: null,
      approvalHistoryData: [],
      approvalHistoryFields: [
        {
          key: 'approver_name'
        },
        { key: 'approver_designation', label: 'Designation' },
        {
          key: 'action_date'
        },
        {
          key: 'seq_order',
          label: 'Approver Order'
        },
        {
          key: 'approval_status',
          label: 'Status'
        },
        {
          key: 'comments'
        }
      ],
      showGrnMappingModal: false,
      grnMappingData: [],
      grnMappingFields: [
        {
          key: 'receipt_num',
          label: 'Receipt Number'
        },
        {
          key: 'receipt_date'
        },
        {
          key: 'invoice_number'
        },
        {
          key: 'invoice_date'
        }
      ],
      validateMsg: null,
      isCurrencyDisabled: false,
      urlSummaryData: null,
      showHistory: false,
      historyType: null,
      totalTaxableAmt: null,
      freightExcTax: null,
      taxExcFreight: null,
      allFlag: false,
      approvalRemarks: null,
      rejectionRemarks: null,
      fileExtension: null,
      showDocDetails: false,
      currentPageIndex: null,
      excelFile: null,
      excelData: [],
      excelHeaders: [],
      showValueSetModal: false,
      showApproveForwardModal: false,
      selectedEmp: { text: null, value: null },
      grnMapFlag: false,
      setTimeVset: null,
      selectedEmpEmail: [],
      selectedEmpId: [],
      showSplitModal: false,
      splitLineData: {},
      splitData: [
        {
          amount: null,
          cost_centre: null,
          expense_month: null,
          from_date: null,
          to_date: null,
          state_code: null,
          concatenated_segments: null,
          distribution_set_id: null,
          percent_distribution: null
        }
      ],
      splitFields: [
        // { key: 'add', stickyColumn: true },
        // { key: 'remove', stickyColumn: true },
        { key: 'amount' },
        { key: 'percent_distribution' },
        { key: 'cost_centre' },
        { key: 'expense_month' },
        { key: 'from_date' },
        { key: 'to_date' },
        { key: 'concatenated_segments', label: 'Code Combination' }
      ],
      lineNo: null,
      poLineNums: [],
      selectedLine: null,
      remainingAmount: null,
      showCostCentreModal: false,
      costCentreData: [],
      costCentreFields: [
        {
          key: 'state_code'
        },
        {
          key: 'cost_centre'
        }
      ],
      costCentreSearch: null,
      perPageCostCentre: commonHelper.perPageRecord,
      totalRowsCostCentre: null,
      currentPageCostCentre: 1,
      finFromDate: null,
      finToDate: null,
      splitFinData: [
        {
          oracle_line_description: null,
          period: null,
          period_to: null,
          unit_price: null,
          days: null,
          amount: null,
          cost_centre: null,
          expense_month: null,
          code_combination: null
        }
      ],
      splitFinFields: [
        { key: 'oracle_line_description' },
        { key: 'period', label: 'Period From' },
        { key: 'period_to', label: 'Period To' },
        { key: 'days', stickyColumn: true },
        { key: 'cost_centre' },
        { key: 'unit_price', label: 'Cost Centre Amount' },
        { key: 'amount', label: 'Distribution Amount' },
        { key: 'expense_month' },
        { key: 'code_combination' },
        { key: 'status_code' },
        { key: 'status_message' }
      ],
      periodTotalDays: [],
      periodFromDates: [],
      periodToDates: [],
      endDateArr: [],
      remainingFinAmt: null,
      operatingUnitSearch: '',
      operatingUnit: { value: null, text: null },
      operatingUnitData: [],
      operatingUnitFields: [
        { key: 'ou_id', label: 'Operating Unit Id' },
        { key: 'ou_name', label: 'Operating Unit' }
      ],
      perPageOperatingUnit: commonHelper.perPageRecord,
      totalRowsOperatingUnit: null,
      currentPageOperatingUnit: 1,
      vendorSiteSearch: '',
      vendorSiteData: [],
      vendorSiteFields: [
        { key: 'vendor_site_id' },
        { key: 'vendor_site_name' }
      ],
      perPageVendorSite: commonHelper.perPageRecord,
      totalRowsVendorSite: null,
      currentPageVendorSite: 1,
      showUploadExcel: false,
      showLineDescriptionModal: false,
      lineDescription: null,
      downloadInvoiceSampleUrl:
        URL_UTILITY.getInvoiceSampleFile +
        '/manualInvoiceExcelUploadFormat.xlsx',
      showSplitModalWithPo: false,
      splitLineDataWithPo: {},
      splitPeriodFromDate: null,
      splitPeriodToDate: null,
      splitWithPoData: [],
      splitWithPoFields: [
        { key: 'period', label: 'From Date' },
        { key: 'period_to', label: 'To Date' },
        { key: 'days', stickyColumn: true },
        { key: 'amount', label: 'Distribution Amount' },
        { key: 'expense_month' }
        // { key: 'code_combination' }
      ],
      remainingAmtWithPo: null,
      dateDisabledFlag: false,
      showDistributionModal: false,
      distribution: { text: null, value: null },
      distributionData: [],
      distributionFields: [
        { key: 'distribution_set_id' },
        { key: 'distribution_set_name' }
      ],
      currentPageDistribution: 1,
      perPageDistribution: commonHelper.perPageRecord,
      pageOptionsDistribution: commonHelper.getPageOption(),
      totalRowsDistribution: null,
      distributionSearch: null,
      distributionSetFlag: false,
      expenseMonth: null,
      finFromDateWithPo: null,
      finToDateWithPo: null,
      dateDisabledFlagWithPo: false,
      splitFinDataWithPo: [],
      splitFinFieldsWithPo: [
        { key: 'oracle_line_description' },
        { key: 'period', label: 'Period From' },
        { key: 'period_to', label: 'Period To' },
        { key: 'days', stickyColumn: true },
        { key: 'amount', label: 'Distribution Amount' },
        { key: 'expense_month' },
        { key: 'code_combination' },
        { key: 'override_flag', label: 'Override', stickyColumn: true }
      ],
      remainingFinAmtWithPo: null,
      dateDisabledFinFlagWithPo: false,
      copyArrItems: [],
      deepCopy: [],
      downloadType: null
      // invoiceLineDataCopy: []
    };
  },
  mounted() {
    if (this.invoiceRowData) {
      // from grid
      this.vendorInvId = this.invoiceRowData.vendor_inv_id;
      this.wfHdrId = this.invoiceRowData.wf_inprocess_hdr_id;
      this.wfDtlId = this.invoiceRowData.wf_inprocess_dtl_id;
      this.approvalStatusKey = this.invoiceRowData.approval_status_key;
      this.menuId = this.$store.state.shared.menuId;
      this.workflowId.menuId = this.wfMenuId;
      this.workflowId.userId = this.userId;
      this.workflowId.eventId = this.eventId;
      this.approvalRemarks = this.invoiceRowData.approval_remarks;
      this.rejectionRemarks = this.invoiceRowData.rejection_remarks;
    }
    if (this.invoiceApprovalDetails) {
      // from dashboard
      this.vendorInvId = this.invoiceApprovalDetails.vendor_inv_id;
      this.wfHdrId = this.invoiceApprovalDetails.wf_inprocess_hdr_id;
      this.wfDtlId = this.invoiceApprovalDetails.wf_inprocess_dtl_id;
      this.approvalStatusKey = this.invoiceApprovalDetails.approval_status_key;
      this.menuId = this.invoiceApprovalDetails.dy_menu_id;
      this.workflowId.menuId = this.invoiceApprovalDetails.wf_menu_id;
      this.workflowId.userId = this.invoiceApprovalDetails.wf_user_id;
      this.workflowId.eventId = this.invoiceApprovalDetails.wf_event_id;
      this.approvalRemarks = this.invoiceApprovalDetails.approval_remarks;
      this.rejectionRemarks = this.invoiceApprovalDetails.rejection_remarks;
    }
    if (this.$route.params.summaryData) {
      this.urlSummaryData = JSON.parse(
        Buffer.from(this.$route.params.summaryData, 'base64').toString('ascii')
      );
    }
    if (this.urlSummaryData) {
      // from url link
      this.vendorInvId = this.urlSummaryData.vendor_inv_id;
      this.wfHdrId = this.urlSummaryData.wf_inprocess_hdr_id;
      this.wfDtlId = this.urlSummaryData.wf_inprocess_dtl_id;
      this.approvalStatusKey = this.urlSummaryData.approval_status_key;
      this.menuId = this.urlSummaryData.dy_menu_id;
      this.workflowId.menuId = this.urlSummaryData.wf_menu_id;
      this.workflowId.userId = this.urlSummaryData.wf_user_id;
      this.workflowId.eventId = this.urlSummaryData.wf_event_id;
      this.approvalRemarks = this.urlSummaryData.approval_remarks;
      this.rejectionRemarks = this.urlSummaryData.rejection_remarks;
    }
    this.getVendorInvoiceDetailsById();
    this.getHistory();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          if (this.headerDetails.approval_status_key !== 'APPROVED') {
            if (this.headerDetails.manual_invoice) {
              this.updateInvoiceDetailsManual();
            } else {
              this.updateInvoiceDetailsFinance();
            }
          } else {
            alert('Record is already APPROVED');
          }
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
    document.addEventListener('keydown', this.handleKeyDown);
    document.oncontextmenu = function() {
      return false;
    };
  },
  methods: {
    viewDocument() {
      if (!this.showDocDetails) {
        this.showDocDetails = true;
        this.getUploadedDocDtl();
      } else {
        this.showDocDetails = false;
      }
    },
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: 100,
        menu_id: 408,
        sub_menu_id: 84,
        record_id: this.vendorInvId
      };
      this.documentData = [];
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page;
              this.documentData = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
              this.totalRows = response.data.data.total_elements;
            } else {
              alert('Document not available for this record');
              this.$emit('closeModal');
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    openPreviewDocInvoice(flag, docDtlId, fileName, index, data) {
      this.currentPage = index + 1;
      const filterDynamicData = data.filter(a => a.original_file_name);
      const getFileName = data[index].original_file_name;
      for (let i = 0; i < filterDynamicData.length; i++) {
        if (filterDynamicData[i].original_file_name == getFileName) {
          this.currentPageIndex = i + 1;
          break;
        }
      }
      const tempData = filterDynamicData.map(elem => {
        return {
          original_file_name: elem.original_file_name,
          document_det_id: elem.doc_detail_id
        };
      });
      this.dynamicData = tempData;
      if (flag) {
        this.getOpenKmAuthTokenPreview(
          flag,
          docDtlId,
          fileName,
          'previewDoc',
          null
        );
      }
    },
    getOpenKmAuthTokenPreview(flag, docDtlId, fileName, type, item) {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (this.openKmAuth && type == 'previewDoc') {
              // this.partOnboardingPreview(flag, docDtlId, this.openKmAuth, fileName);
              // need to change
              this.previewOpenDoc(docDtlId, this.openKmAuth, fileName, flag);
              // this.showHideOpenModal(docDtlId, this.openKmAuth, fileName, flag);
            } else {
              this.newPreviewOpenDoc(
                this.documentData[item - 1].original_file_name,
                this.documentData[item - 1].doc_detail_id
              );
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthToken(docDtlId, fileName, type) {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (type === 'new_window') {
              this.openDocumentNewTab(docDtlId, fileName, this.openKmAuth);
            } else {
              const url =
                this.downloadDocUrl +
                '/download/' +
                docDtlId +
                '?token=' +
                this.openKmAuth;
              commonHelper.downloadFile(url, fileName);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getPageIndex(item) {
      this.getOpenKmAuthTokenPreview(null, null, null, 'newPreviewDoc', item);
      this.specIndex = item;
    },
    newPreviewOpenDoc(uploadedFileName, docDtlId) {
      this.fileExtension = commonHelper.getFileExtension(uploadedFileName);
      // this.eventBus.$emit('changeFileName', uploadedFileName);
      const payload = {
        docDetailId: docDtlId,
        token: this.openKmAuth,
        fileExtension: this.fileExtension
      };
      this.loader = true;
      commonHelper.previewImgDoc(payload, () => (this.loader = false));
    },
    previewOpenDoc(docDtlId, openKmAuth, fileName) {
      this.fileExtension = commonHelper.getFileExtension(fileName);
      const payload = {
        docDetailId: docDtlId,
        token: openKmAuth,
        fileExtension: this.fileExtension
      };
      this.loader = true;
      this.tempFunc(payload, () => (this.loader = false));
    },
    tempFunc(payload, callback) {
      const url =
        URL_UTILITY.getOpenKmDocDeleteUrl +
        '/preview/' +
        payload.docDetailId +
        '?token=' +
        payload.token;
      const token = sessionStorage.getItem('token');
      fetch(url, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${token}`
        })
      })
        .then(response => {
          return response.blob();
        })
        .then(blob => {
          let imgLink = null;
          if (payload.fileExtension === 'pdf') {
            imgLink = window.URL.createObjectURL(
              new Blob([blob], { type: 'application/pdf' })
            );
            document.querySelector('#prev-docs').src = imgLink;
          } else if (payload.fileExtension === 'xlsx') {
            this.readExcelBlob(blob);
          } else {
            imgLink = window.URL.createObjectURL(new Blob([blob]));
            document.querySelector('#prev-docs').src = imgLink + '#toolbar=0';
          }
        })
        .finally(() => {
          callback();
        });
    },
    afterOpen() {
      this.$refs.excelViewer.setRowBackgroundColor(1, '#b0d5f3');
    },
    async readExcelBlob(blob) {
      const arrayBuffer = await this.readBlobAsArrayBuffer(blob);
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(arrayBuffer);
      const worksheet = workbook.getWorksheet(1);
      this.excelHeaders = worksheet.getRow(1).values;
      // this.excelData = worksheet.getSheetValues().slice(1);
      this.excelData = [];
      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        if (rowNumber > 1) {
          const rowData = row.values;
          this.excelData.push(rowData);
        }
      });
    },
    readBlobAsArrayBuffer(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
        reader.readAsArrayBuffer(blob);
      });
    },
    defaultExpenseMonth(date) {
      const parts = date.split('-');
      const month = parts[1].toUpperCase();
      const year = parts[2].slice(2);
      this.headerDetails.period_name = month + '-' + year;
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
    },
    saveFunction() {
      if (this.editMode) {
        if (this.headerDetails.approval_status_key !== 'APPROVED') {
          this.updateInvoiceDetailsFinance();
        } else {
          alert('Record is already APPROVED');
        }
      }
    },
    setFieldsForFinance() {
      if (this.headerDetails.invoice_level === 'INVOICE') {
        this.invoiceLineFields.push(
          { key: 'period_name', label: 'Expense Month' },
          { key: 'tds_flag', label: 'TDS Flag', stickyColumn: true },
          { key: 'tds_section_meaning', label: 'TDS Section' },
          { key: 'tax_rate_name', label: 'TDS Rate' },
          { key: 'split', stickyColumn: true }
        );
      }
      // else if (this.headerDetails.invoice_level === 'GRN') {
      //   this.invoiceLineFields.push({
      //     key: 'split',
      //     stickyColumn: true
      //   });
      // }
    },
    getVendorInvoiceDetailsById() {
      const payload = {
        vendor_inv_id: this.vendorInvId,
        user_id: this.workflowId.userId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getVendorInvoiceDetailsById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.headerDetails = result;
            if (this.headerDetails.po_num) {
              this.headerDetails.remarks = `INV No-${this.headerDetails.inv_num} | PO-${
                this.headerDetails.po_num
              } | GRN-${
                this.headerDetails.receipt_num
                  ? this.headerDetails.receipt_num
                  : ''
              } | EXP MON-${this.headerDetails.period_name} | PO Hdr Dsc `;
            }
            this.operatingUnit.text = result.manual_ou_name;
            this.operatingUnit.value = result.manual_ou_id;
            this.approvalStatusKey = result.approval_status_key;
            this.headerDetails.show_inv_amt = commonHelper.formatAmountWithoutCurrency(
              result.inv_amt
            );
            this.invoiceLineData = result.invoice_dtls.map(elem => {
              // elem.taxable_amount = elem.deliver_qty * elem.po_price;
              elem.period_name = result.period_name;
              return elem;
            });
            if (
              result.actual_gr_date &&
              result.period_name &&
              result.material_received_by
            ) {
              this.isComDetailsSaved = true;
            } else {
              this.isComDetailsSaved = false;
            }
            if (result.gl_date) {
              this.isFinDetailsSaved = true;
            } else {
              this.isFinDetailsSaved = false;
            }
            this.validateCurrency();
            this.getTotalTaxAmount();
            if (this.headerDetails.invoice_level == 'INVOICE') {
              this.headerDetails.gl_date = !this.headerDetails.gl_date
                ? format(new Date(), appStrings.DATEFNSFORMAT)
                : this.headerDetails.gl_date;
            }
            if (this.headerDetails.manual_invoice) {
              if (this.headerDetails.invoice_level === 'INVOICE') {
                this.invoiceLineFields = [
                  {
                    key: 'manual_invoice_line_id',
                    label: 'Manual Invoice Line No.'
                  },
                  {
                    key: 'item_description',
                    label: 'Description'
                  },
                  {
                    key: 'po_price',
                    label: 'PO Price',
                    class: 'col-width-fix'
                  },
                  {
                    key: 'deliver_qty',
                    label: 'Qty. Being Delivered',
                    class: 'qty-width-fix'
                  },
                  {
                    key: 'hsn_sac',
                    label: 'HSN/SAC',
                    class: 'col-width-fix'
                  },
                  { key: 'taxable_amount', class: 'tax-amt-fix' },
                  {
                    key: 'tax_amount',
                    class: 'text-fix-po'
                  },
                  {
                    key: 'tax_non_recoverable',
                    label: 'Tax Non Recoverable'
                  },
                  {
                    key: 'status_code',
                    label: 'GRN Status',
                    class: 'col-width-fix'
                  },
                  {
                    key: 'status_msg',
                    label: 'Error Message'
                  },
                  {
                    key: 'tax_details',
                    label: 'Tax',
                    stickyColumn: true
                  },
                  {
                    key: 'split',
                    stickyColumn: true
                  }
                ];
              } else {
                this.invoiceLineFields = [
                  {
                    key: 'manual_invoice_line_id',
                    label: 'Manual Invoice Line No.'
                  },
                  {
                    key: 'item_description',
                    label: 'Description'
                  },
                  {
                    key: 'po_price',
                    label: 'PO Price',
                    class: 'col-width-fix'
                  },
                  {
                    key: 'deliver_qty',
                    label: 'Qty. Being Delivered',
                    class: 'qty-width-fix'
                  },
                  {
                    key: 'hsn_sac',
                    label: 'HSN/SAC',
                    class: 'col-width-fix'
                  },
                  { key: 'taxable_amount', class: 'tax-amt-fix' },
                  {
                    key: 'tax_amount',
                    class: 'text-fix-po'
                  },
                  {
                    key: 'tax_details',
                    label: 'Tax',
                    stickyColumn: true
                  },
                  {
                    key: 'split',
                    stickyColumn: true
                  }
                ];
              }
            } else {
              this.setFieldsForFinance();
            }
            // if (!this.headerDetails.manual_invoice) {
            //   if (this.headerDetails.invoice_level == 'INVOICE') {
            //     this.headerDetails.remarks = this.headerDetails.remarks
            //       ? this.headerDetails.remarks
            //       : this.headerDetails.inv_num +
            //         ' | ' +
            //         this.headerDetails.vendor_name +
            //         ' | ' +
            //         this.headerDetails.po_num;
            //   }
            // }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHidePreviewTaxModal(flag, item) {
      this.showPreviewTaxModal = flag;
      if (flag) {
        this.previewTaxData = item.tax_details;
        // this.previewTaxData.map(ele => {
        //   if (ele.manual !== 'Y') {
        //     ele.taxable_amount = item.deliver_qty * item.po_price;
        //   } else {
        //     ele.taxable_amount = null;
        //   }
        // });
      }
    },
    formattingAmount(amount) {
      return commonHelper.formatAmountWithoutCurrency(amount);
    },
    showHideDocumentModal(flag) {
      this.showDocumentModal = flag;
    },
    showHideRejectModal(flag) {
      this.showRejectModal = flag;
      if (!flag) {
        this.rejectedReason = null;
      }
    },
    isSplitAvail(invoiceLevel, type) {
      if (invoiceLevel === 'BUYER') {
        if (type === 'approve_forward') {
          if (
            this.operatingUnit.value &&
            this.headerDetails.vendor_site_id &&
            this.headerDetails.remarks
          ) {
            this.updateInvoiceDetailsManual();
            this.showHideApproveForwardModal(true);
          } else {
            alert('Please Enter Mandatory Fields');
          }
        } else if (type === 'approve') {
          const isCCSaved = this.invoiceLineData.filter(ele => !ele.cc_flag);
          if (isCCSaved.length) {
            alert('Kindly Save Cost Centre Details.');
          } else {
            this.showHideApproveModal(true);
          }
        }
        // const isCCSaved = this.invoiceLineData.filter(ele => !ele.cc_flag);
        // if (isCCSaved.length) {
        //   alert('Kindly Save Cost Centre Details.');
        // } else {
        //   if (type === 'approve_forward') {
        //     this.showHideApproveForwardModal(true);
        //   }
        //   if (type === 'approve') {
        //     this.showHideApproveModal(true);
        //   }
        // }
      } else if (invoiceLevel === 'INVOICE') {
        if (type === 'approve_forward') {
          this.showHideApproveForwardModal(true);
        } else if (type === 'approve') {
          const isDistSaved = this.invoiceLineData.filter(ele => !ele.fin_flag);
          if (isDistSaved.length) {
            alert('Kindly Save Distribution Details.');
          } else {
            this.showHideApproveModal(true);
          }
        }
        // const isDistSaved = this.invoiceLineData.filter(ele => !ele.fin_flag);
        // if (isDistSaved.length) {
        //   alert('Kindly Save Distribution Details.');
        // } else {
        //   if (type === 'approve_forward') {
        //     this.showHideApproveForwardModal(true);
        //   }
        //   if (type === 'approve') {
        //     this.showHideApproveModal(true);
        //   }
        // }
      }
    },
    showHideApproveModal(flag) {
      if (!this.headerDetails.manual_invoice) {
        if (this.headerDetails.invoice_level === 'INVOICE') {
          if (
            this.headerDetails.receipt_num &&
            this.headerDetails.grn_attach_flag
          ) {
            this.showApproveModal = flag;
          } else {
            alert('GRN Not Created Yet/GRN pdf not attached');
          }
        } else {
          this.showApproveModal = flag;
        }
      } else {
        this.showApproveModal = flag;
      }
      if (!flag) {
        this.approvalReason = null;
      }
    },
    isRecieptNumberAvail() {
      if (this.headerDetails.receipt_num) {
        this.showHideApproveModal(true);
      } else {
        alert('GRN Not Created Yet/GRN pdf not attached');
      }
    },
    approveRejectInvoiceApproval(status) {
      const payload = {
        event_id: this.workflowId.eventId,
        wf_inprocess_dtl_id: this.wfDtlId,
        notification_status: status,
        notification_comments:
          status === 'REJECTED' ? this.rejectedReason : this.approvalReason
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/approvalRejectSingle', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.getVendorInvoiceDetailsById();
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            if (status === 'REJECTED') {
              this.showHideRejectModal(false);
            } else if (status === 'APPROVED') {
              this.showHideApproveModal(false);
            }
            this.$emit('updateInvoice');
          } else {
            this.showAlert = false;
            alert(response.response.data.message);
            // this.isSuccess = false;
            // this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideInvoiceCommonModal(flag, modalType, index, type) {
      this.modalType = modalType;
      this.specIndex = index;
      this.expenseType = type;
      if (flag) {
        if (this.modalType === 'expense_month') {
          this.getExpenseMonth();
        } else if (this.modalType === 'tds_section') {
          this.getTdsSection();
        } else if (this.modalType === 'tds_rate') {
          this.getTdsRate();
        } else if (this.modalType === 'operating_unit') {
          this.getOperatingUnitDetails();
        } else if (this.modalType === 'vendor_site') {
          this.getVendorSiteDetails();
        }
      }
      if (!flag) {
        this.specIndex = null;
        this.perPageTdsRate = commonHelper.perPageRecord;
        this.totalRowsTdsRate = null;
        this.currentPageTdsRate = 1;
      }
      this.showInvoiceCommonModal = flag;
    },
    getExpenseMonth() {
      const payload = {
        _page: this.currentPageExpense - 1,
        _limit: this.perPageExpense,
        po_creation_date: this.headerDetails.po_creation_date,
        period_name: this.expenseMonthSearch
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getExpenseMonth', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.expenseData = result;
            this.totalRowsExpense = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getTdsSection() {
      const payload = {
        _page: this.currentPageTdsSection - 1,
        _limit: this.perPageTdsSection,
        section_name: this.tdsSectionSearch
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getTdsSection', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.tdsSectionData = result;
            this.totalRowsTdsSection = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getTdsRate() {
      const payload = {
        _page: this.currentPageTdsRate - 1,
        _limit: this.perPageTdsRate,
        org_id: this.headerDetails.ou_id,
        section_code: this.invoiceLineData[this.specIndex].tds_section_code,
        tds_rate: this.tdsRateSearch
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getTdsRate', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.tdsRateData = result;
            this.totalRowsTdsRate = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedExpense(item) {
      if (this.expenseType == 'header') {
        this.headerDetails.period_name = item.period_name;
      } else if (this.expenseType == 'line') {
        this.invoiceLineData[this.specIndex].period_name = item.period_name;
        this.invoiceLineData = JSON.parse(JSON.stringify(this.invoiceLineData));
      } else if (this.expenseType == 'line_cc') {
        this.splitData[this.specIndex].expense_month = item.period_name;
        this.splitData = JSON.parse(JSON.stringify(this.splitData));
      } else if (this.expenseType == 'with_po_expense') {
        this.splitFinDataWithPo[this.specIndex].expense_month =
          item.period_name;
        // this.splitData = JSON.parse(JSON.stringify(this.splitData));
      } else if (this.expenseType == 'header_expense') {
        if (this.splitData.length) {
          this.expenseMonth = item.period_name;
          this.splitData = this.splitData.map(ele => {
            ele.expense_month = item.period_name;
            return ele;
          });
        } else {
          alert('Kindly Enter Cost Centre Details First');
        }
      }
      this.perPageExpense = commonHelper.perPageRecord;
      this.totalRowsExpense = null;
      this.currentPageExpense = 1;
      this.showInvoiceCommonModal = false;
    },
    rowSelectedTdsSection(item) {
      this.invoiceLineData[this.specIndex].tds_section_code = item.lookup_code;
      this.invoiceLineData[this.specIndex].tds_section_meaning = item.meaning;
      this.invoiceLineData = JSON.parse(JSON.stringify(this.invoiceLineData));
      this.showInvoiceCommonModal = false;
      this.perPageTdsSection = commonHelper.perPageRecord;
      this.totalRowsTdsSection = null;
      this.currentPageTdsSection = 1;
    },
    rowSelectedTdsRate(item) {
      this.invoiceLineData[this.specIndex].tax_rate_id = item.tax_rate_id;
      this.invoiceLineData[this.specIndex].tax_rate_name = item.tax_rate_name;
      this.invoiceLineData = JSON.parse(JSON.stringify(this.invoiceLineData));
      this.showInvoiceCommonModal = false;
      this.perPageTdsRate = commonHelper.perPageRecord;
      this.totalRowsTdsRate = null;
      this.currentPageTdsRate = 1;
    },
    validateFunction(rate) {
      var regex = /^\d+(\.\d{0,4})?$/g;
      if (rate) {
        if (!regex.test(rate)) {
          alert('Exchange Rate cannot greater than four decimal places');
          this.headerDetails.exchange_rate = null;
        }
      }
    },

    clearTdsValues(index) {
      this.invoiceLineData[index].tds_section_code = null;
      this.invoiceLineData[index].tds_section_meaning = null;
      this.invoiceLineData[index].tax_rate_id = null;
      this.invoiceLineData[index].tax_rate_name = null;
    },
    clearVsetValues(segment, index) {
      if (segment === 'expense-month') {
        this.invoiceLineData[index].period_name = null;
      } else if (segment === 'expense_month_cc') {
        this.splitData[index].expense_month = null;
        this.splitData = JSON.parse(JSON.stringify(this.splitData));
      } else if (segment === 'tds_section') {
        this.invoiceLineData[index].tds_section_code = null;
        this.invoiceLineData[index].tds_section_meaning = null;
      } else if (segment === 'tds_rate') {
        this.invoiceLineData[index].tax_rate_id = null;
        this.invoiceLineData[index].tax_rate_name = null;
      } else if (segment === 'cost_centre') {
        this.splitData[index].cost_centre = null;
      } else if (segment === 'expense_month_header') {
        this.headerDetails.period_name = null;
        this.headerDetails = JSON.parse(JSON.stringify(this.headerDetails));
      } else if (segment === this.selectedEmp.text) {
        this.selectedEmp.text = null;
        this.selectedEmp.value = null;
      } else if (segment === 'operating_unit') {
        this.operatingUnit.text = null;
        this.operatingUnit.value = null;
        this.headerDetails.vendor_site_id = null;
        this.headerDetails.vendor_site_name = null;
      } else if (segment === 'vendor_site') {
        this.headerDetails.vendor_site_id = null;
        this.headerDetails.vendor_site_name = null;
      } else if (segment === this.distribution.value) {
        this.distribution.text = null;
        this.distribution.value = null;
        this.splitData = [];
      } else if (segment === this.expenseMonth) {
        this.expenseMonth = null;
        this.splitData = this.splitData.map(ele => {
          ele.expense_month = null;
          return ele;
        });
      }
    },
    allDetailsAvail() {
      if (this.headerDetails.invoice_level !== 'INVOICE') {
        if (
          this.headerDetails.actual_gr_date &&
          this.headerDetails.period_name &&
          this.headerDetails.material_received_by
        ) {
          return true;
        } else {
          alert('Please Enter all the mandatory fields');
        }
      }
      if (this.headerDetails.invoice_level === 'INVOICE') {
        if (this.headerDetails.gl_date) {
          return true;
        } else {
          alert('Please Enter GL Date');
        }
      }
    },
    updateInvoiceDetailsManual() {
      if (
        this.headerDetails.remarks &&
        this.operatingUnit.text &&
        this.headerDetails.vendor_site_id
      ) {
        const invoiceDetails = this.invoiceLineData.map(elem => {
          return {
            vendor_inv_dtl_id: elem.vendor_inv_dtl_id,
            period_name: elem.period_name,
            tds_flag: elem.tds_flag,
            tds_section_code: elem.tds_section_code,
            tds_section_meaning: elem.tds_section_meaning,
            tax_rate_id: elem.tax_rate_id,
            tax_rate_name: elem.tax_rate_name,
            tax_non_recoverable: elem.tax_non_recoverable
              ? elem.tax_non_recoverable
              : false
          };
        });
        // if (invoiceDetails.length) {
        const payload = {
          vendor_invoice_id: this.vendorInvId,
          remarks: this.headerDetails.remarks,
          manual_ou_id: this.operatingUnit.value,
          manual_ou_name: this.operatingUnit.text,
          vendor_site_id: this.headerDetails.vendor_site_id,
          vendor_site_name: this.headerDetails.vendor_site_name,
          gl_date: this.headerDetails.gl_date,
          vendor_invoice_dtls: invoiceDetails ? invoiceDetails : null
        };
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/updateInvoiceDetailsFinance', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.vendorInvId = response.data.data.vendor_inv_id;
              this.getVendorInvoiceDetailsById();
              this.isSuccess = true;
              this.responseMsg = response.data.message;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      } else {
        alert('Please Enter Mandatory Fields');
      }
    },
    updateInvoiceDetailsFinance() {
      // const invoiceLineMapping = [];
      // for (let i = 0; i < this.invoiceLineData.length; i++) {
      //   if (
      //     this.invoiceLineData[i].vendor_inv_dtl_id !== 0 &&
      //     this.invoiceLineData[i].vendor_inv_dtl_id ==
      //       this.invoiceLineDataCopy[i].vendor_inv_dtl_id
      //   ) {
      //     if (
      //       this.invoiceLineData[i].period_name !==
      //         this.invoiceLineDataCopy[i].period_name ||
      //       this.invoiceLineData[i].tds_flag !==
      //         this.invoiceLineDataCopy[i].tds_flag ||
      //       this.invoiceLineData[i].tds_section_code !==
      //         this.invoiceLineDataCopy[i].tds_section_code ||
      //       this.invoiceLineData[i].tax_rate_id !==
      //         this.invoiceLineDataCopy[i].tax_rate_id
      //     ) {
      //       invoiceLineMapping.push(this.invoiceLineData[i]);
      //     }
      //   } else if (this.invoiceLineData[i].vendor_inv_dtl_id === 0) {
      //     invoiceLineMapping.push(this.invoiceLineData[i]);
      //   }
      // }
      if (this.allDetailsAvail()) {
        const invoiceDetails = this.invoiceLineData.map(elem => {
          return {
            vendor_inv_dtl_id: elem.vendor_inv_dtl_id,
            period_name: elem.period_name,
            tds_flag: elem.tds_flag,
            tds_section_code: elem.tds_section_code,
            tds_section_meaning: elem.tds_section_meaning,
            tax_rate_id: elem.tax_rate_id,
            tax_rate_name: elem.tax_rate_name
          };
        });
        if (invoiceDetails.length) {
          const payload = {
            vendor_invoice_id: this.vendorInvId,
            actual_gr_date: this.headerDetails.actual_gr_date,
            gl_date: this.headerDetails.gl_date,
            period_name: this.headerDetails.period_name,
            material_received_by: this.headerDetails.material_received_by,
            exchange_rate: this.headerDetails.exchange_rate
              ? +this.headerDetails.exchange_rate
              : null,
            remarks: this.headerDetails.remarks,
            manual_ou_id: this.operatingUnit.value,
            manual_ou_name: this.operatingUnit.text,
            vendor_invoice_dtls: invoiceDetails ? invoiceDetails : null
          };
          this.loader = true;
          this.$store
            .dispatch('intWorkflow/updateInvoiceDetailsFinance', payload)
            .then(response => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 200) {
                this.editMode = false;
                this.vendorInvId = response.data.data.vendor_inv_id;
                this.getVendorInvoiceDetailsById();
                this.isSuccess = true;
                this.responseMsg = response.data.message;
              } else {
                this.isSuccess = false;
                this.responseMsg = response.response.data.message;
              }
            })
            .catch(() => {
              this.loader = false;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
            });
        }
      }
    },
    getValidateGlDate(glDate) {
      if (glDate) {
        const payload = {
          le_id: this.headerDetails.erp_le_id,
          gl_date: glDate
        };
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/getValidateGlDate', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.validateMsg =
                response.data.data.data.CLOSING_STATUS == 'O'
                  ? 'OPEN'
                  : response.data.data.data.CLOSING_STATUS == 'N'
                  ? 'NOT OPEN'
                  : '';
              if (this.validateMsg !== 'OPEN') {
                this.headerDetails.gl_date = null;
                alert('Period is Not Open For GL Date');
              }
              if (this.validateMsg == 'OPEN') {
                this.headerDetails.gl_date = glDate;
                this.responseMsg = `${response.data.message} , GL Date status is ${this.validateMsg}.`;
              }
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    grnDisabledDates(date) {
      const restrictedFromDate = new Date(this.headerDetails.po_creation_date);
      restrictedFromDate.setHours(0, 0, 0, 0);
      const restrictedToDate = new Date();
      restrictedToDate.setHours(0, 0, 0, 0);
      return date < restrictedFromDate || date > restrictedToDate;
    },
    glDisabledDates(date) {
      const restrictedFromDate = new Date(this.headerDetails.inv_date);
      restrictedFromDate.setHours(0, 0, 0, 0);
      const restrictedToDate = new Date();
      restrictedToDate.setHours(0, 0, 0, 0);
      return date < restrictedFromDate || date > restrictedToDate;
    },
    getPeriodIndex(index) {
      this.specIndex = index;
    },
    getPeriodToIndex(index) {
      this.specIndex = index;
    },
    periodDisabledDates(date) {
      const restrictedFromDate = new Date(
        this.splitFinDataWithPo[this.specIndex].period
      );
      restrictedFromDate.setHours(0, 0, 0, 0);
      const restrictedToDate = new Date(this.finToDateWithPo);
      restrictedToDate.setHours(0, 0, 0, 0);
      return date < restrictedFromDate || date > restrictedToDate;
    },
    finWithoutPoDisabledDates(date) {
      const restrictedFromDate = new Date(this.finFromDate);
      restrictedFromDate.setHours(0, 0, 0, 0);
      return date < restrictedFromDate;
    },
    finWithPoDisabledDates(date) {
      const restrictedFromDate = new Date(this.finFromDateWithPo);
      restrictedFromDate.setHours(0, 0, 0, 0);
      return date < restrictedFromDate;
    },
    createGrnInvoiceApproval() {
      const filterInvoiceDetails = this.invoiceLineData.map(elem => {
        return {
          child_trx_id: elem.vendor_inv_dtl_id,
          po_header_id: this.headerDetails.po_header_id,
          po_line_id: elem.po_line_id,
          po_line_location_id: elem.po_line_location_id,
          po_distribution_id: elem.po_distribution_id,
          rec_qty: elem.quantity,
          grn_dtl_id: elem.grn_dtl_id,
          trx_id_dtls: this.headerDetails.vendor_inv_id
        };
      });
      if (filterInvoiceDetails.length) {
        const payload = {
          inv_hdr_id: this.headerDetails.vendor_inv_id,
          ou_id: this.headerDetails.ou_id,
          po_header_id: this.headerDetails.po_header_id,
          vendor_id: this.headerDetails.vendor_id,
          vendor_site_id: this.headerDetails.vendor_site_id,
          invoice_number: this.headerDetails.inv_num,
          invoice_amount: this.headerDetails.inv_amt,
          invoice_date: this.headerDetails.inv_date,
          grn_hdr_id: 0,
          dtls: filterInvoiceDetails ? filterInvoiceDetails : null
        };
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/createGrnInvoiceApproval', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    truncateComment(souce, size) {
      return commonHelper.truncate(souce, size);
    },
    showHideGrnMappingModal(flag) {
      this.showGrnMappingModal = flag;
      if (flag) {
        this.getGrnReceiptMappingData();
      } else if (!flag && this.grnMapFlag) {
        this.setTimeVset = setTimeout(() => {
          const promt = confirm(
            'Would you like to approve without GRN Mapping.?'
          );
          if (promt) {
            this.showHideApproveModal(true);
          }
        }, 500);
      }
    },
    validateGrnMappingModal() {
      this.grnMapFlag = false;
      this.showHideGrnMappingModal(true);
    },
    getGrnReceiptMappingData() {
      const payload = {
        _page: this.currentPageGrnMapping - 1,
        _limit: this.perPageGrnMapping,
        po_num: this.headerDetails.po_num
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getGrnReceiptMappingData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.grnMappingData = response.data.data.data.page;
            this.totalRowsGrnMapping = response.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedGrnMapping(item) {
      this.showGrnMappingModal = false;
      this.getGrnReceiptDetailsData(item);
    },
    validateCurrency() {
      const payload = {
        legal_entity_id: this.headerDetails.erp_le_id
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/validateCurrencyExchangeRate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.data;
            if (result) {
              if (result.CURRENCY_CODE == this.headerDetails.currency) {
                this.isCurrencyDisabled = true;
              } else {
                this.isCurrencyDisabled = false;
              }
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    dateValidation(invoiceLevel) {
      if (invoiceLevel === 'BUYER' || invoiceLevel === 'GRN') {
        this.isCurrentDateInRangeFunction(invoiceLevel);
      } else {
        this.checkGrnMappingValidation(invoiceLevel);
      }
    },
    isCurrentDateInRangeFunction(invoiceLevel) {
      const filterInvoiceLineDetails = this.invoiceLineData.map(elem => {
        return {
          po_distribution_id: elem.po_distribution_id
        };
      });
      const payload = {
        po_header_id: this.headerDetails.po_header_id,
        po_line_data: filterInvoiceLineDetails
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/isCurrentDateInRange', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
            if (result.length) {
              if (result[0].po_status === 'APPROVED') {
                const rejectAvail = result.filter(
                  data => data.receipt_days_exception_code === 'REJECT'
                );
                if (rejectAvail.length) {
                  const expectedEarlyDeliveryDate = rejectAvail
                    .filter(ele => {
                      if (ele.expected_early_delivery_date) {
                        return new Date(ele.expected_early_delivery_date);
                      }
                    })
                    .map(data => new Date(data.expected_early_delivery_date));
                  const expectedLateDeliveryDate = rejectAvail
                    .filter(ele => {
                      if (ele.expected_late_delivery_date) {
                        return new Date(ele.expected_late_delivery_date);
                      }
                    })
                    .map(data => new Date(data.expected_late_delivery_date));
                  if (
                    expectedEarlyDeliveryDate.length &&
                    expectedLateDeliveryDate.length
                  ) {
                    var currentDate = new Date();
                    var maxDate = new Date(
                      Math.max(...expectedEarlyDeliveryDate)
                    );
                    var minDate = new Date(
                      Math.min(...expectedLateDeliveryDate)
                    );
                    // return currentDate >= maxDate && currentDate <= minDate;
                    if (currentDate >= maxDate && currentDate <= minDate) {
                      this.checkGrnMappingValidation(invoiceLevel);
                    } else {
                      alert(
                        'Kindly, contact to the buyer for change the need date in the PO'
                      );
                    }
                  } else {
                    this.checkGrnMappingValidation(invoiceLevel);
                    // return true;
                  }
                } else {
                  this.checkGrnMappingValidation(invoiceLevel);
                  // return true;
                }
              } else {
                alert(`PO Status is: ${result[0].po_status}`);
              }
            }
          } else {
            alert(response.response.data.message);
          }
        })
        .catch(() => {
          this.loader = false;
          alert(appStrings.autoFailedMsg);
        });
    },
    isCurrentDateInRange() {
      const rejectAvail = this.invoiceLineData.filter(
        data => data.receipt_days_exception_code === 'REJECT'
      );
      if (!rejectAvail.length) {
        return true;
      }
      const expectedEarlyDeliveryDates = rejectAvail
        .map(ele =>
          ele.expected_early_delivery_date
            ? new Date(ele.expected_early_delivery_date)
            : null
        )
        .filter(date => date);
      const expectedLateDeliveryDates = rejectAvail
        .map(ele =>
          ele.expected_late_delivery_date
            ? new Date(ele.expected_late_delivery_date)
            : null
        )
        .filter(date => date);
      if (
        !expectedEarlyDeliveryDates.length ||
        !expectedLateDeliveryDates.length
      ) {
        return true;
      }
      const currentDate = new Date();
      const maxDate = new Date(Math.max(...expectedEarlyDeliveryDates));
      const minDate = new Date(Math.min(...expectedLateDeliveryDates));
      return currentDate >= maxDate && currentDate <= minDate;
    },
    // isCurrentDateInRange() {
    //   const rejectAvail = this.invoiceLineData.filter(
    //     data => data.receipt_days_exception_code === 'REJECT'
    //   );
    //   if (rejectAvail.length) {
    //     const expectedEarlyDeliveryDate = rejectAvail
    //       .filter(ele => {
    //         if (ele.expected_early_delivery_date) {
    //           return new Date(ele.expected_early_delivery_date);
    //         }
    //       })
    //       .map(data => new Date(data.expected_early_delivery_date));
    //     const expectedLateDeliveryDate = rejectAvail
    //       .filter(ele => {
    //         if (ele.expected_late_delivery_date) {
    //           return new Date(ele.expected_late_delivery_date);
    //         }
    //       })
    //       .map(data => new Date(data.expected_late_delivery_date));
    //     if (
    //       expectedEarlyDeliveryDate.length &&
    //       expectedLateDeliveryDate.length
    //     ) {
    //       var currentDate = new Date();
    //       var maxDate = new Date(Math.max(...expectedEarlyDeliveryDate));
    //       var minDate = new Date(Math.min(...expectedLateDeliveryDate));
    //       return currentDate >= maxDate && currentDate <= minDate;
    //     } else {
    //       return true;
    //     }
    //   } else {
    //     return true;
    //   }
    // },
    checkGrnMappingValidation(invoiceLevel) {
      if (invoiceLevel === 'GRN') {
        if (this.headerDetails.receipt_num) {
          this.showHideApproveModal(true);
        } else {
          this.grnMapFlag = true;
          this.getGrnReceiptDataAvail();
        }
      } else {
        this.showHideApproveModal(true);
      }
    },
    getGrnReceiptDataAvail() {
      const payload = {
        _page: 0,
        _limit: 1000,
        po_num: this.headerDetails.po_num
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getGrnReceiptMappingData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.data.page;
            if (result.length) {
              this.showHideGrnMappingModal(true);
              const final = result
                .filter(elem => elem.invoice_number)
                .map(data => data.invoice_number);
              const isGrnNumAvail = final.includes(this.headerDetails.inv_num);
              const grnMapIndex = final.indexOf(this.headerDetails.inv_num);
              if (isGrnNumAvail) {
                if (grnMapIndex > -1) {
                  this.getGrnReceiptDetailsData(
                    result[grnMapIndex],
                    'auto_map'
                  );
                }
              }
            } else {
              this.showHideApproveModal(true);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    // New Function Starting

    getGrnReceiptDetailsData(item, type) {
      const payload = {
        _page: 0,
        _limit: 100,
        shipment_hdr_id: item.shipment_header_id
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getGrnReceiptDetailsData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data.data.page;
            if (result) {
              if (result.length == this.invoiceLineData.length) {
                const allMismatchedParams = [];
                for (let i = 0; i < this.invoiceLineData.length; i++) {
                  const item1 = this.invoiceLineData[i];
                  const matchedItem2 = result.find(
                    item2 =>
                      item2.po_distribution_id === item1.po_distribution_id
                  );
                  if (!matchedItem2) {
                    alert(
                      `Error: No matching item found for PO Distribution ID: ${item1.po_distribution_id}.`
                    );
                    // return `Error: No matching item found for po_line_location_id ${item1.po_line_location_id} in array2.`;
                  }
                  const mismatchedParams = [];
                  if (item1.po_line_id !== matchedItem2.po_line_id) {
                    mismatchedParams.push('PO Line Id Not Matched');
                  }
                  if (item1.deliver_qty !== matchedItem2.grn_qty) {
                    mismatchedParams.push('Deliver Qty. Not Matched');
                  }
                  if (item1.po_price !== matchedItem2.unit_price) {
                    mismatchedParams.push('PO Price not matched');
                  }
                  if (item1.item_code !== matchedItem2.item_code) {
                    mismatchedParams.push('Item Code Not Matched');
                  }
                  if (item1.tax_amount !== matchedItem2.tax) {
                    mismatchedParams.push('TAX Amount Not Matched');
                  }
                  if (mismatchedParams.length > 0) {
                    allMismatchedParams.push(
                      `PO Line Location ID: ${
                        item1.po_line_location_id
                      }: ${mismatchedParams.join(', ')}`
                    );
                  }
                  // if (mismatchedParams.length > 0) {
                  //   const errorMsg = `Error: Parameters do not match for po_line_location_id ${
                  //     item1.po_line_location_id
                  //   }: ${mismatchedParams.join(', ')}.`;
                  //   alert(errorMsg);
                  // }
                }
                if (allMismatchedParams.length > 0) {
                  const defaultMsg =
                    'GRN Validation not applicable for following reason(s) :\n';
                  let errorMsg = '';
                  allMismatchedParams.forEach(item => {
                    errorMsg += item + '\n';
                  });
                  alert(defaultMsg + errorMsg);
                  // const errorMsg = `Error: Parameters do not match for the following items:\n${allMismatchedParams.join(
                  //   '\n'
                  // )}.`;
                  // alert(errorMsg);
                } else {
                  this.grnMappingForInvoiceApproval(result, type);
                }
              } else if (result.length !== this.invoiceLineData.length) {
                alert('Invoice Line(s) Not Matched with GRN');
              }
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    // New Function Ending
    grnMappingForInvoiceApproval(result, type) {
      const lineDetails = result.map(elem => {
        return {
          po_line_id: elem.po_line_id,
          po_line_location_id: elem.po_line_location_id,
          shipment_line_id: elem.shipment_line_id,
          po_distribution_id: elem.po_distribution_id,
          deliver_qty: elem.grn_qty
        };
      });
      const payload = {
        vendor_inv_id: this.vendorInvId,
        receipt_num: result[0].receipt_num,
        auto_grn_flag: type === 'auto_map' ? true : false,
        shipment_hdr_id: result[0].shipment_hdr_id,
        vendor_invoice_dtls: lineDetails ? lineDetails : null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/grnMappingForInvoiceApproval', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.getVendorInvoiceDetailsById();
            if (type === 'auto_map') {
              this.grnMapFlag = false;
              alert('Automatically GRN Map Successfully.');
              this.showHideGrnMappingModal(false);
              this.showHideApproveModal(true);
            }
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    switchVariable() {
      this.allFlag = this.allFlag
        ? (this.allFlag = false)
        : this.allFlag == false
        ? (this.allFlag = true)
        : null;
      this.getHistory();
      const cardElement = document.getElementById('accordion-3');
      const isCardOpen = cardElement.classList.contains('show');
      if (!isCardOpen) {
        cardElement.__vue__.$root.$emit('bv::toggle::collapse', 'accordion-3');
      }
    },
    getHistory() {
      const payload = {
        eventId: this.workflowId.eventId,
        nfaHdrId: this.vendorInvId,
        approvalStatus: this.allFlag ? 'Y' : !this.allFlag ? 'N' : ''
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getApprovalList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.approvalHistoryData = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getTotalTaxAmount() {
      const payload = {
        vendor_inv_id: this.vendorInvId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getTotalTaxAmount', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.totalTaxableAmt = commonHelper.formatAmountWithoutCurrency(
              response.data.data.taxable_amount_sum
            );
            this.freightExcTax = commonHelper.formatAmountWithoutCurrency(
              response.data.data.taxable_amount_line_sum
            );
            this.taxExcFreight = commonHelper.formatAmountWithoutCurrency(
              response.data.data.tax_amount_sum
            );
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.EMP_ID_BY_GRP_ID) {
        this.selectedEmpEmail.push(item.value_code);
        if (!this.selectedEmpId.includes(item.employee_id)) {
          this.selectedEmpId.push({ approver_id: +item.employee_id });
        }
      }
    },
    removeTags(tags, tagsId, tag) {
      const index = tags.indexOf(tag);
      if (index !== -1) {
        tags.splice(index, 1);
        tagsId.splice(index, 1);
      }
    },
    adjustTagsWidth() {
      const tagsWidth = this.$refs.tags.$el.scrollWidth;
      this.$refs.tags.$el.style.width = `${tagsWidth}px`;
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    showHideApproveForwardModal(flag) {
      this.showApproveForwardModal = flag;
      if (!flag) {
        this.selectedEmp = { text: null, value: null };
        this.approvalReason = null;
        this.selectedEmpEmail = [];
        this.selectedEmpId = [];
      }
    },
    approveForwardInvoice() {
      const payload = {
        event_id: this.workflowId.eventId,
        inprocess_hdr_id: this.wfHdrId,
        inprocess_dtl_id: this.wfDtlId,
        approver_ids: this.selectedEmpId,
        notification_comments: this.approvalReason
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/approveForwardInvoice', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            alert('Approve and Forward Successfully');
            this.getVendorInvoiceDetailsById();
            this.showHideApproveForwardModal(false);
            this.$emit('updateInvoice');
          } else {
            alert(response.response.data.message);
          }
        })
        .catch(() => {
          alert(appStrings.autoFailedMsg);
        });
    },
    autoClickNextPage() {
      const nextPage = this.currentPage + 1;
      if (nextPage <= this.totalRows / this.previewPage) {
        this.currentPage = nextPage;
        this.getPageIndex(nextPage);
      }
    },
    autoClickPrevPage() {
      const prevPage = this.currentPage - 1;
      if (prevPage > 0) {
        this.currentPage = prevPage;
        this.getPageIndex(prevPage);
      }
    },
    handleKeyDown(event) {
      // if (
      //   event.keyCode == '17' ||
      //   event.keyCode == '91' ||
      //   event.keyCode == '92' ||
      //   event.keyCode == '16' ||
      //   event.keyCode == '83'
      // ) {
      //   event.preventDefault();
      //   alert('Not Allowed');
      // } else
      if (event.keyCode == '39') {
        this.autoClickNextPage();
      } else if (event.keyCode == '37') {
        this.autoClickPrevPage();
      }
    },
    getOpenKmAuthTokenUploadDocument(docDtlId) {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (this.openKmAuth) {
              if (docDtlId) {
                this.deleteRow(docDtlId, this.openKmAuth);
              } else {
                this.showHideHistory(true, 'uploadDocument');
              }
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    deleteRow(docDetailId, openKmAuth) {
      const payload = {
        docDetailId: docDetailId,
        token: openKmAuth
      };
      if (docDetailId !== 0) {
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/delteOpenKmDoc', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              alert('Document Delete Successfully');
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
              this.getUploadedDocDtl();
            } else {
              this.isSuccess = false;
              this.respMessage =
                response.response.data.message || 'Something went wrong';
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    onSelectOrgType(event) {
      const record = this.invoiceLineData.find(
        item => item.vendor_inv_dtl_id === event
      );
      if (record) {
        const promt = confirm('Are you sure, you want to change line?');
        if (promt) {
          this.newFunctionById(record);
        }
      } else {
        alert('Line Not Found');
      }
    },
    newFunctionById(record) {
      const payload = {
        vendor_invoice_dtl_id: record.vendor_inv_dtl_id
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getSplitLineDetailsById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            if (result.length) {
              this.splitData = result;
              this.repeatFunction(record);
            } else {
              this.splitData = [];
              this.repeatFunction(record);
            }
            if (
              this.headerDetails.manual_invoice &&
              this.headerDetails.invoice_level === 'INVOICE'
            ) {
              this.getSplitLineFinDetailsById();
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    repeatFunction(record) {
      this.splitLineData = record;
      this.splitLineData = JSON.parse(JSON.stringify(this.splitLineData));
      this.splitLineData.po_price = this.splitLineData.po_price.toFixed(2);
      this.splitLineData.taxable_amount = this.splitLineData.taxable_amount.toFixed(
        2
      );
      this.splitLineData.tax_amount = this.splitLineData.tax_amount.toFixed(2);
      const total = this.splitData
        .filter(ele => ele.amount)
        .map(data => data.amount);
      const totalAmount = commonHelper.calculateTotal(total).toFixed(2);
      this.remainingAmount = this.splitLineData.taxable_amount - totalAmount;
      this.remainingAmount = this.remainingAmount.toFixed(2);
    },
    splitFunction(item, index) {
      if (this.headerDetails.invoice_level === 'BUYER') {
        this.updateInvoiceDetailsManual();
      } else if (this.headerDetails.invoice_level === 'INVOICE') {
        if (this.headerDetails.gl_date) {
          this.updateInvoiceDetailsManual();
        } else {
          alert('Please Enter GL Date');
        }
      }
      if (
        this.headerDetails.invoice_level === 'BUYER'
          ? this.operatingUnit.value &&
            this.headerDetails.remarks &&
            this.headerDetails.vendor_site_id
          : this.headerDetails.invoice_level === 'INVOICE'
          ? this.operatingUnit.value &&
            this.headerDetails.remarks &&
            this.headerDetails.vendor_site_id &&
            this.headerDetails.gl_date
          : this.headerDetails.invoice_level === null
      ) {
        this.splitLineData = item;
        this.splitLineData = JSON.parse(JSON.stringify(this.splitLineData));
        this.splitLineData.po_price = this.splitLineData.po_price.toFixed(2);
        this.splitLineData.taxable_amount = this.splitLineData.taxable_amount.toFixed(
          2
        );
        this.splitLineData.tax_amount = this.splitLineData.tax_amount.toFixed(
          2
        );
        this.remainingAmount = this.splitLineData.taxable_amount;
        this.splitData = [];
        this.splitFinData = [];
        if (item.period_from_date) {
          this.finFromDate = item.period_from_date;
        } else {
          this.finFromDate = null;
        }
        if (item.period_to_date) {
          this.finToDate = item.period_to_date;
        } else {
          this.finToDate = null;
        }
        this.lineNo = null;
        if (
          (this.headerDetails.invoice_level === 'BUYER' &&
            this.approvalStatusKey === 'APPROVED') ||
          this.headerDetails.invoice_level === null
        ) {
          this.splitFields = [
            { key: 'from_date' },
            { key: 'to_date' },
            { key: 'percent_distribution' },
            { key: 'amount' },
            { key: 'cost_centre' },
            { key: 'expense_month' },
            { key: 'concatenated_segments', label: 'Code Combination' }
          ];
        }
        if (this.headerDetails.invoice_level === 'INVOICE') {
          this.splitFields = [
            { key: 'from_date' },
            { key: 'to_date' },
            { key: 'percent_distribution' },
            { key: 'amount' },
            { key: 'cost_centre' },
            { key: 'expense_month' },
            { key: 'concatenated_segments', label: 'Code Combination' }
          ];
        }
        this.showHideSplitModal(true, index);
      }
    },
    defaultDateSetInvoice() {
      if (this.splitData.length) {
        const data = JSON.parse(JSON.stringify(this.splitData));
        const minDate = new Date(
          Math.min(...data.map(item => new Date(item.from_date)))
        );
        const maxDate = new Date(
          Math.max(...data.map(item => new Date(item.to_date)))
        );
        if (minDate && maxDate) {
          this.finFromDate = commonHelper.formattedDate(minDate);
          this.finToDate = commonHelper.formattedDate(maxDate);
        }
      }
    },
    showHideSplitModal(flag, index) {
      this.showSplitModal = flag;
      if (flag) {
        const valueAndTextArr = this.invoiceLineData.map(type => {
          return {
            value: type.vendor_inv_dtl_id,
            text: type.manual_invoice_line_id
          };
        });
        this.poLineNums = valueAndTextArr;
        this.selectedLine = this.poLineNums[index].value;
        if (flag) {
          this.getSplitLineDetailsById();
          if (
            this.headerDetails.manual_invoice &&
            this.headerDetails.invoice_level === 'INVOICE'
          ) {
            this.getSplitLineFinDetailsById();
          }
        }
      }
      if (!flag) {
        this.getVendorInvoiceDetailsById();
      }
      // setTimeout(() => {
      //   this.defaultDateSetInvoice();
      // }, 500);
    },
    addSplitLines(lineNo, taxableAmount) {
      if (lineNo) {
        if (lineNo > 99) {
          alert('Line No cannot be greater than 99');
          this.lineNo = null;
        } else {
          if (!this.splitData.length) {
            const divideAmount = taxableAmount / lineNo;
            let totalAmount = 0;
            for (let i = 0; i < lineNo; i++) {
              this.splitData.push({
                amount: Number.isInteger(divideAmount)
                  ? divideAmount.toFixed(2)
                  : commonHelper.splitAmountDecimal(divideAmount, 2),
                cost_centre: null
              });
            }
            for (let i = 0; i < this.splitData.length - 1; i++) {
              totalAmount += +this.splitData[i].amount;
            }
            this.splitData[lineNo - 1].amount = parseFloat(
              this.splitLineData.taxable_amount - totalAmount
            ).toFixed(2);
            this.lineNo = null;
            if (this.headerDetails.period_name) {
              this.splitData.map(
                ele => (ele.expense_month = this.headerDetails.period_name)
              );
            }
          } else {
            const promt = confirm('Are you sure, you want to add new line(s)?');
            if (promt) {
              this.splitData = [];
              const divideAmount = taxableAmount / lineNo;
              let totalAmount = 0;
              for (let i = 0; i < lineNo; i++) {
                this.splitData.push({
                  amount: Number.isInteger(divideAmount)
                    ? divideAmount.toFixed(2)
                    : commonHelper.splitAmountDecimal(divideAmount, 2),
                  cost_centre: null
                });
              }
              for (let i = 0; i < this.splitData.length - 1; i++) {
                totalAmount += +this.splitData[i].amount;
              }
              this.splitData[lineNo - 1].amount = parseFloat(
                this.splitLineData.taxable_amount - totalAmount
              ).toFixed(2);
              this.lineNo = null;
              if (this.headerDetails.period_name) {
                this.splitData.map(
                  ele => (ele.expense_month = this.headerDetails.period_name)
                );
              }
            }
          }
          const filterAmt = this.splitData
            .filter(ele => ele.amount)
            .map(item => item.amount);
          if (filterAmt.length) {
            const totalFilterAmt =
              filterAmt.length > 1
                ? commonHelper.calculateTotal(filterAmt).toFixed(2)
                : parseFloat(filterAmt[0]).toFixed(2);
            this.remainingAmount =
              this.splitLineData.taxable_amount - totalFilterAmt;
            this.remainingAmount = parseFloat(this.remainingAmount).toFixed(2);
          }
        }
      }
    },
    isRemainingAmtAvail() {
      if (this.remainingAmount == 0) {
        this.addEditSplitLineData();
      } else {
        alert('Remaining Amount Should be 0');
      }
    },
    isRemainingAmtAvailFin() {
      if (this.remainingFinAmt == 0) {
        this.addEditSplitLineFinData();
      } else {
        alert('Remaining Amount Should be 0');
      }
    },
    isRemainingAmtAvailWithPo() {
      if (this.remainingAmtWithPo == 0) {
        this.addEditSplitLineWithPo();
      } else {
        alert('Remaining Amount Should be 0');
      }
    },
    isRemainingAmtAvailFinWithPo() {
      if (this.remainingFinAmtWithPo == 0) {
        this.addEditSplitLineFinWithPo();
      } else {
        alert('Remaining Amount Should be 0');
      }
    },
    addEditSplitLineFinWithPo() {
      const filterData = this.splitFinDataWithPo.filter(
        ele =>
          !ele.code_combination || !ele.period || !ele.period_to || !ele.days
      );
      if (filterData.length) {
        alert('Please Enter Mandatory Fields.');
      } else {
        const splitFinWithPoDetails = this.splitFinDataWithPo.map(elem => {
          return {
            inv_distribution_dtl_id: elem.inv_distribution_dtl_id
              ? elem.inv_distribution_dtl_id
              : 0,
            oracle_line_description: elem.oracle_line_description,
            period_from: elem.period,
            period_to: elem.period_to,
            days: elem.days,
            distribution_amount: elem.amount,
            expense_month: elem.expense_month,
            code_combination: elem.code_combination,
            override_flag: elem.override_flag ? elem.override_flag : false
          };
        });
        const payload = {
          vendor_invoice_dtl_id: this.splitLineDataWithPo.vendor_inv_dtl_id,
          distribution_dtls: splitFinWithPoDetails
            ? splitFinWithPoDetails
            : null
        };
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/addEditSplitLineFinWithPo', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.getSplitLineFinWithPoDetailsById();
              alert(response.data.message);
            } else {
              alert(response.response.data.message);
            }
          })
          .catch(() => {
            this.loader = false;
            alert(appStrings.autoFailedMsg);
          });
      }
    },
    getSplitLineFinWithPoDetailsById() {
      const payload = {
        vendor_invoice_dtl_id: this.splitLineDataWithPo.vendor_inv_dtl_id
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getSplitLineFinWithPoDetailsById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.splitFinDataWithPo = result.map(ele => {
              ele.amount = ele.distribution_amount;
              ele.period = ele.period_from;
              return ele;
            });
            if (this.splitFinDataWithPo.length) {
              const data = JSON.parse(JSON.stringify(this.splitFinDataWithPo));
              const minDate = new Date(
                Math.min(...data.map(item => new Date(item.period)))
              );
              const maxDate = new Date(
                Math.max(...data.map(item => new Date(item.period_to)))
              );
              if (minDate && maxDate) {
                this.finFromDateWithPo = commonHelper.formattedDate(minDate);
                this.finToDateWithPo = commonHelper.formattedDate(maxDate);
              }
              const total = this.splitFinDataWithPo
                .filter(ele => ele.amount)
                .map(data => data.amount);
              const totalAmount = commonHelper.calculateTotal(total).toFixed(2);
              this.remainingFinAmtWithPo =
                this.splitLineDataWithPo.taxable_amount - totalAmount;
              this.remainingFinAmtWithPo = this.remainingFinAmtWithPo.toFixed(
                2
              );
            } else {
              this.remainingFinAmtWithPo =
                this.splitLineDataWithPo.taxable_amount - 0;
              this.remainingFinAmtWithPo = this.remainingFinAmtWithPo.toFixed(
                2
              );
            }
            this.isDateDisabledFinWithPo();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditSplitLineWithPo() {
      const filterData = this.splitWithPoData.filter(ele => ele.amount == null);
      if (filterData.length) {
        alert('Please Enter Distribution Amount');
      } else {
        const splitFinDetails = this.splitWithPoData.map(elem => {
          return {
            inv_distribution_dtl_id: elem.inv_distribution_dtl_id
              ? elem.inv_distribution_dtl_id
              : 0,
            period_from: elem.period,
            period_to: elem.period_to,
            days: elem.days,
            distribution_amount: elem.amount,
            expense_month: elem.expense_month
            // code_combination: elem.code_combination
          };
        });
        const payload = {
          vendor_invoice_dtl_id: this.splitLineDataWithPo.vendor_inv_dtl_id,
          distribution_dtls: splitFinDetails ? splitFinDetails : null
        };
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/addEditSplitLineWithPo', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.getSplitLineWithPoById();
              this.splitPeriodFromDate = null;
              this.splitPeriodToDate = null;
              alert(response.data.message);
            } else {
              alert(response.response.data.message);
            }
          })
          .catch(() => {
            this.loader = false;
            alert(appStrings.autoFailedMsg);
          });
      }
    },
    addEditSplitLineData(flag) {
      const filterData = this.splitData.filter(
        ele =>
          !ele.cost_centre ||
          !ele.expense_month ||
          !ele.from_date ||
          !ele.to_date
      );
      if (filterData.length) {
        alert('Please Enter Mandatory Fields.');
      } else {
        const splitDetails = this.splitData.map(elem => {
          return {
            inv_cost_centre_id: elem.inv_cost_centre_id
              ? elem.inv_cost_centre_id
              : 0,
            amount: elem.amount,
            expense_month: elem.expense_month,
            cost_centre: elem.cost_centre,
            state_code: elem.state_code,
            from_date:
              this.headerDetails.invoice_level === 'BUYER'
                ? elem.from_date
                : this.finFromDate,
            to_date:
              this.headerDetails.invoice_level === 'BUYER'
                ? elem.to_date
                : this.finToDate,
            concatenated_segments: elem.concatenated_segments,
            distribution_set_id: elem.distribution_set_id,
            percent_distribution: elem.percent_distribution
          };
        });
        const payload = {
          vendor_invoice_dtl_id: this.splitLineData.vendor_inv_dtl_id,
          split_dtls: splitDetails ? splitDetails : null
        };
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/addEditSplitLineData', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.getSplitLineDetailsById();
              if (!flag) {
                alert(response.data.message);
              }
            } else {
              alert(response.response.data.message);
            }
          })
          .catch(() => {
            this.loader = false;
            alert(appStrings.autoFailedMsg);
          });
      }
    },
    getSplitLineDetailsById() {
      const payload = {
        vendor_invoice_dtl_id: this.splitLineData.vendor_inv_dtl_id
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getSplitLineDetailsById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            if (result.length) {
              this.splitData = result;
              const total = this.splitData
                .filter(ele => ele.amount)
                .map(data => data.amount);
              const totalAmount = commonHelper.calculateTotal(total).toFixed(2);
              this.remainingAmount =
                this.splitLineData.taxable_amount - totalAmount;
              this.remainingAmount = this.remainingAmount.toFixed(2);
            } else {
              this.splitData = [];
              this.distribution = { text: null, value: null };
              this.expenseMonth = null;
              this.remainingAmount = this.splitLineData.taxable_amount;
              this.remainingAmount = this.remainingAmount.toFixed(2);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        })
        .finally(() => {
          this.isDistributionDetailsSaved();
        });
    },
    addEditSplitLineFinData() {
      const filterData = this.splitFinData.filter(
        ele => ele.amount == null || !ele.code_combination
      );
      if (filterData.length) {
        alert('Please Enter Distribution Amount and Code Combination');
      } else {
        const splitFinDetails = this.splitFinData.map(elem => {
          return {
            inv_distribution_dtl_id: elem.inv_distribution_dtl_id
              ? elem.inv_distribution_dtl_id
              : 0,
            oracle_line_description: elem.oracle_line_description,
            period_from: elem.period,
            period_to: elem.period_to,
            cost_centre_amount: elem.unit_price,
            days: elem.days,
            distribution_amount: elem.amount,
            cost_centre: elem.cost_centre,
            expense_month: elem.expense_month,
            code_combination: elem.code_combination
          };
        });
        const payload = {
          vendor_invoice_dtl_id: this.splitLineData.vendor_inv_dtl_id,
          distribution_dtls: splitFinDetails ? splitFinDetails : null
        };
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/addEditSplitLineFinData', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              if (this.finFromDate && this.finToDate) {
                this.addEditSplitLineData(true);
              }
              this.getSplitLineFinDetailsById();
              this.finFromDate = null;
              this.finToDate = null;
              alert(response.data.message);
            } else {
              alert(response.response.data.message);
            }
          })
          .catch(() => {
            this.loader = false;
            alert(appStrings.autoFailedMsg);
          });
      }
    },
    getSplitLineFinDetailsById() {
      const payload = {
        vendor_invoice_dtl_id: this.splitLineData.vendor_inv_dtl_id
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getSplitLineFinDetailsById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.splitFinData = result.map(ele => {
              ele.unit_price = ele.cost_centre_amount;
              ele.amount = ele.distribution_amount;
              ele.period = ele.period_from;
              return ele;
            });
            if (this.splitFinData.length) {
              const total = this.splitFinData
                .filter(ele => ele.amount)
                .map(data => data.amount);
              const totalAmount = commonHelper.calculateTotal(total).toFixed(2);
              this.remainingFinAmt =
                this.splitLineData.taxable_amount - totalAmount;
              this.remainingFinAmt = this.remainingFinAmt.toFixed(2);
            } else {
              this.remainingFinAmt = this.splitLineData.taxable_amount - 0;
              this.remainingFinAmt = this.remainingFinAmt.toFixed(2);
            }
            this.isDateDisabled();
            setTimeout(() => {
              this.defaultDateSetInvoice();
            }, 500);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      if (this.approvalStatusKey !== 'APPROVED')
        if (this.remainingAmount == 0) {
          alert('Remaining Amount Not Available');
        } else {
          this.splitData.push({
            amount: null,
            cost_centre: null,
            expense_month: null,
            from_date: null,
            to_date: null,
            state_code: null,
            concatenated_segments: null,
            distribution_set_id: null
          });
        }
    },
    removeRow(index, item) {
      this.splitData.splice(index, 1);
      this.calculateAmount(item);
    },
    calculateAmount(item) {
      const totalLineAmount = this.splitData
        .filter(ele => ele.amount)
        .map(data => data.amount);
      if (totalLineAmount.length) {
        const finalAmount =
          totalLineAmount.length > 1
            ? commonHelper.calculateTotal(totalLineAmount).toFixed(2)
            : parseFloat(totalLineAmount[0]).toFixed(2);
        if (finalAmount > +this.splitLineData.taxable_amount) {
          alert('Amount cannot be greater than Remaining Amount');
          item.amount = null;
          this.calculateAmount(item);
        } else {
          this.remainingAmount =
            +this.splitLineData.taxable_amount - finalAmount;
          this.remainingAmount = this.remainingAmount.toFixed(2);
        }
      } else {
        this.remainingAmount = this.splitLineData.taxable_amount;
      }
    },
    calculateAmountFinance(item) {
      const totalLineAmount = this.splitFinData
        .filter(ele => ele.amount)
        .map(data => data.amount);
      if (totalLineAmount.length) {
        const finalAmount =
          totalLineAmount.length > 1
            ? commonHelper.calculateTotal(totalLineAmount).toFixed(2)
            : parseFloat(totalLineAmount[0]).toFixed(2);
        if (finalAmount > +this.splitLineData.taxable_amount) {
          alert('Amount cannot be greater than Remaining Amount');
          item.amount = null;
          this.calculateAmountFinance(item);
        } else {
          this.remainingFinAmt =
            +this.splitLineData.taxable_amount - finalAmount;
          this.remainingFinAmt = this.remainingFinAmt.toFixed(2);
        }
      } else {
        this.remainingFinAmt = this.splitLineData.taxable_amount;
      }
    },
    calculateAmountFinanceWithPo(item) {
      const totalLineAmount = this.splitFinDataWithPo
        .filter(ele => ele.amount)
        .map(data => data.amount);
      if (totalLineAmount.length) {
        const finalAmount =
          totalLineAmount.length > 1
            ? commonHelper.calculateTotal(totalLineAmount).toFixed(2)
            : parseFloat(totalLineAmount[0]).toFixed(2);
        if (finalAmount > +this.splitLineDataWithPo.taxable_amount) {
          alert('Amount cannot be greater than Remaining Amount');
          item.amount = null;
          this.calculateAmountFinanceWithPo(item);
        } else {
          this.remainingFinAmtWithPo =
            +this.splitLineDataWithPo.taxable_amount - finalAmount;
          this.remainingFinAmtWithPo = this.remainingFinAmtWithPo.toFixed(2);
        }
      } else {
        this.remainingFinAmtWithPo = this.splitLineDataWithPo.taxable_amount;
      }
    },
    showHideCostCentreModal(flag, index) {
      this.specIndex = index;
      this.showCostCentreModal = flag;
      if (flag) {
        this.getCostCentreDetails();
      }
    },
    getCostCentreDetails() {
      const payload = {
        _page: this.currentPageCostCentre - 1,
        _limit: this.perPageCostCentre,
        org_id: this.operatingUnit.value,
        company_state_code: this.headerDetails.state_code,
        cost_centre: this.costCentreSearch
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getCostCentreDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.costCentreData = result;
            this.totalRowsCostCentre = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedCostCentre(item) {
      this.splitData[this.specIndex].cost_centre = item.cost_centre;
      this.splitData[this.specIndex].state_code = item.state_code;
      this.perPageCostCentre = commonHelper.perPageRecord;
      this.totalRowsCostCentre = null;
      this.currentPageCostCentre = 1;
      this.showCostCentreModal = false;
    },
    periodToWithPo(item) {
      if (new Date(item.period_to) > new Date(this.finToDateWithPo)) {
        item.period_to = null;
        alert('Period To Date cannot greater than To Date');
      }
      if (item.period && item.period_to) {
        const totalDays = this.getTotalDays(item.period_to, item.period);
        item.days = totalDays;
      }
    },
    periodWithPo(item) {
      item.period_to = null;
      item.days = null;
    },
    isOverrideChange(item, flag, index) {
      if (flag) {
        item.createdIndex = index;
        this.copyArrItems.push(item);
        this.deepCopy = JSON.parse(JSON.stringify(this.copyArrItems));
      } else {
        this.copyArrItems = this.copyArrItems.filter(obj => obj !== item);
        const filterCopyItem = this.deepCopy.find(
          copyItem => copyItem.createdIndex === index
        );
        if (filterCopyItem) {
          filterCopyItem.override_flag = false;
          this.splitFinDataWithPo[index] = { ...filterCopyItem };
          this.splitFinDataWithPo = [...this.splitFinDataWithPo];
        }
      }
    },
    // isOverrideChange(item, flag, index) {
    //   if (flag) {
    //     item.createdIndex = index;
    //     this.copyArrItems.push(item);
    //     this.deepCopy = JSON.parse(JSON.stringify(this.copyArrItems));
    //   } else if (!flag) {
    //     this.copyArrItems = this.copyArrItems.filter(obj => obj !== item);
    //     const filterCopyItem = this.deepCopy.filter(item => {
    //       if (item.createdIndex == index) {
    //         return item;
    //       }
    //     });
    //     filterCopyItem[0].override_flag = false;
    //     this.splitFinDataWithPo[index] = filterCopyItem[0];
    //     this.splitFinDataWithPo = JSON.parse(
    //       JSON.stringify(this.splitFinDataWithPo)
    //     );
    //   }
    // },
    compareDate(periodFromDate, glDate) {
      const temp_period_date =
        new Date(periodFromDate).getMonth() +
        1 +
        '-' +
        new Date(periodFromDate).getFullYear();
      const temp_gl_date =
        new Date(glDate).getMonth() + 1 + '-' + new Date(glDate).getFullYear();
      const parseDate = dateString => {
        const [month, year] = dateString.split('-');
        return new Date(year, month - 1);
      };
      const period_date = parseDate(temp_period_date);
      const gl_date = parseDate(temp_gl_date);
      if (period_date <= gl_date) {
        return (
          periodFromDate.split('-')[1].toUpperCase() +
          '-' +
          periodFromDate
            .split('-')[2]
            .toString()
            .slice(2)
        );
      } else {
        return 'PREPAID';
      }
    },
    calculateDateFunction() {
      if (this.finFromDate && this.finToDate) {
        this.splitFinData = [];
        const periodTotalDays = this.getCustomDays(
          this.finToDate,
          this.finFromDate
        );
        const totalDays = this.getTotalDays(this.finToDate, this.finFromDate);
        for (let i = 0; i < this.splitData.length; i++) {
          for (let j = 0; j < periodTotalDays.length; j++) {
            const currentDate = new Date(this.headerDetails.gl_date);
            const calAmt =
              (this.splitData[i].amount * periodTotalDays[j]) / totalDays;
            this.splitFinData.push({
              oracle_line_description:
                (this.splitLineData.item_description
                  ? this.splitLineData.item_description
                  : '') +
                ' | ' +
                (this.headerDetails.remarks ? this.headerDetails.remarks : '') +
                ' | ' +
                (this.splitData[i].expense_month
                  ? this.splitData[i].expense_month
                  : '') +
                ' | ' +
                (this.periodFromDates[j] ? this.periodFromDates[j] : '') +
                ' | ' +
                (this.periodToDates[j]
                  ? format(this.periodToDates[j], 'dd-MMM-yyyy')
                  : ''),
              period: this.periodFromDates[j]
                ? this.periodFromDates[j]
                : 'PREPAID',
              period_to: this.periodToDates[j]
                ? format(this.periodToDates[j], 'dd-MMM-yyyy')
                : null,
              unit_price: this.splitData[i].amount,
              days: periodTotalDays[j],
              amount: calAmt.toFixed(2),
              cost_centre: this.splitData[i].cost_centre,
              // expense_month: this.compareDate(
              //   this.periodFromDates[j],
              //   this.headerDetails.gl_date
              // ),
              expense_month:
                // new Date(this.periodFromDates[j]).getMonth() + 1 <=
                // new Date(this.headerDetails.gl_date).getMonth() + 1
                new Date(this.periodFromDates[j]) <=
                new Date(
                  currentDate.getFullYear(),
                  currentDate.getMonth() + 1,
                  0
                )
                  ? this.periodFromDates[j].split('-')[1].toUpperCase() +
                    '-' +
                    this.periodFromDates[j]
                      .split('-')[2]
                      .toString()
                      .slice(2)
                  : 'PREPAID',
              // expense_month: this.periodFromDates[j]
              //   ? this.periodFromDates[j].split('-')[1].toUpperCase() +
              //     '-' +
              //     this.periodFromDates[j]
              //       .split('-')[2]
              //       .toString()
              //       .slice(2)
              //   : 'PREPAID',
              code_combination: this.splitData[i].concatenated_segments,
              new_key:
                new Date(this.finToDate) <=
                new Date(
                  currentDate.getFullYear(),
                  currentDate.getMonth() + 1,
                  0
                )
                  ? this.finToDate.split('-')[1].toUpperCase() +
                      '-' +
                      this.finToDate.split('-')[2] ==
                    this.periodFromDates[j].split('-')[1].toUpperCase() +
                      '-' +
                      this.periodFromDates[j].split('-')[2]
                    ? 'POSTPAID'
                    : null
                  : null
              // expense_month: this.splitData[i].expense_month
            });
          }
        }
        const finArrCopy = JSON.parse(JSON.stringify(this.splitFinData));
        let result = [];
        // while (finArrCopy.length > 0) {
        // for (let i = 0; i < finArrCopy.length; i++)
        for (let i = finArrCopy.length; i > 0; i--) {
          const findIndex = finArrCopy.findIndex(
            ele => ele.expense_month === 'PREPAID'
          );
          const findIndexPostpaid = finArrCopy.findIndex(
            ele => ele.new_key === 'POSTPAID'
          );
          if (findIndex !== -1) {
            let prepaidExpenses = finArrCopy.splice(0, findIndex + 1);
            result.push(prepaidExpenses);
            for (let i = 0; i < result.length; i++) {
              let sum = 0;
              result[i].filter(ele => {
                if (ele.expense_month !== 'PREPAID') {
                  sum += parseFloat(ele.amount);
                }
              });
              result[i].filter(data => {
                if (data.expense_month === 'PREPAID') {
                  data.amount = data.unit_price - sum;
                  data.amount = data.amount.toFixed(2);
                }
              });
            }
          } else {
            let postpaidExpenses = finArrCopy.splice(0, findIndexPostpaid + 1);
            result.push(postpaidExpenses);
            for (let i = 0; i < result.length; i++) {
              let sum = 0;
              result[i].filter(ele => {
                if (ele.new_key !== 'POSTPAID') {
                  sum += parseFloat(ele.amount);
                }
              });
              result[i].filter(data => {
                if (data.new_key === 'POSTPAID') {
                  data.amount = this.splitData[i].amount - sum;
                  data.amount = data.amount.toFixed(2);
                }
              });
            }
          }
          let tempArr = [];
          for (let i = 0; i < result.length; i++) {
            result[i].forEach(ele => {
              tempArr.push(ele);
            });
          }
          if (findIndex !== -1) {
            this.splitFinData = tempArr;
          } else if (findIndexPostpaid !== -1) {
            this.splitFinData = tempArr;
          }
        }
        const filterAmt = this.splitFinData
          .filter(ele => ele.amount)
          .map(item => item.amount);
        if (filterAmt.length) {
          const totalFilterAmt =
            filterAmt.length > 1
              ? commonHelper.calculateTotal(filterAmt).toFixed(2)
              : parseFloat(filterAmt[0]).toFixed(2);
          this.remainingFinAmt =
            this.splitLineData.taxable_amount - totalFilterAmt;
          this.remainingFinAmt = parseFloat(this.remainingFinAmt).toFixed(2);
        }
      } else {
        if (this.finFromDate == null && this.finToDate == null) {
          const tempArr = this.splitData.map(item => ({
            oracle_line_description:
              (this.splitLineData.item_description
                ? this.splitLineData.item_description
                : '') +
              ' | ' +
              (this.headerDetails.remarks ? this.headerDetails.remarks : '') +
              ' | ' +
              (item.expense_month ? item.expense_month : '') +
              ' | ' +
              (item.from_date ? item.from_date : '') +
              ' | ' +
              (item.to_date ? item.to_date : ''),
            period: item.from_date,
            period_to: item.to_date,
            days: this.getTotalDays(item.to_date, item.from_date),
            unit_price: item.amount,
            amount: item.amount,
            cost_centre: item.cost_centre,
            expense_month: item.expense_month,
            code_combination: item.concatenated_segments
          }));
          this.splitFinData = tempArr;
          const filterAmt = this.splitFinData
            .filter(ele => ele.amount)
            .map(item => item.amount);
          if (filterAmt.length) {
            const totalFilterAmt =
              filterAmt.length > 1
                ? commonHelper.calculateTotal(filterAmt).toFixed(2)
                : parseFloat(filterAmt[0]).toFixed(2);
            this.remainingFinAmt =
              this.splitLineData.taxable_amount - totalFilterAmt;
            this.remainingFinAmt = parseFloat(this.remainingFinAmt).toFixed(2);
          }
        } else {
          alert('Kindly Enter From Date and To Date');
        }
      }
    },
    dateClearFunction() {
      this.finToDate = null;
      this.splitFinData = [];
    },
    getTotalDays(toDate, fromDate) {
      const { differenceInCalendarDays } = require('date-fns');
      const totalDays =
        differenceInCalendarDays(new Date(toDate), new Date(fromDate)) + 1;
      return totalDays;
    },
    getCustomDays(toDate, fromDate) {
      const {
        getDaysInMonth,
        addMonths,
        startOfMonth,
        differenceInDays,
        endOfMonth
      } = require('date-fns');
      // const currentDate = new Date();
      const currentDate = new Date(this.headerDetails.gl_date);
      let startDate = startOfMonth(new Date(fromDate));
      let endDate = new Date(toDate);
      let periodTotalDays = [];
      let periodFromDates = [];
      let periodToDates = [];
      while (startDate <= endDate) {
        const daysInMonth = Math.min(
          getDaysInMonth(startDate),
          differenceInDays(endDate, startDate) + 1
        );
        if (startDate <= currentDate) {
          periodFromDates.push(format(startDate, 'dd-MMM-yyyy'));
          periodToDates.push(endOfMonth(new Date(startDate)));
          periodTotalDays.push(daysInMonth);
        } else {
          periodTotalDays.push(differenceInDays(endDate, startDate) + 1);
          break;
        }
        startDate = addMonths(startDate, 1);
      }
      periodFromDates[0] = commonHelper.formattedDate(fromDate); // new added
      periodFromDates.push(commonHelper.formattedDate(startDate)); // new added
      // if (
      //   new Date(toDate).getMonth() + 1 >
      //   new Date(currentDate).getMonth() + 1
      // )
      if (new Date(toDate) > new Date(currentDate)) {
        periodToDates.push(new Date(toDate)); // new added
      } else {
        periodToDates[periodToDates.length - 1] = new Date(toDate); // new added
      }
      const newTempDate = fromDate.split('-');
      periodTotalDays[0] = periodTotalDays[0] - newTempDate[0] + 1;
      this.periodFromDates = periodFromDates;
      this.periodToDates = periodToDates;
      return periodTotalDays;
    },
    getOperatingUnitDetails() {
      const payload = {
        le_id: this.headerDetails.erp_le_id,
        ou_name: this.operatingUnitSearch
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getOperatingUnitDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data;
            this.operatingUnitData = result;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedOperatingUnit(item) {
      this.operatingUnit.text = item.ou_name;
      this.operatingUnit.value = item.ou_id;
      this.headerDetails.vendor_site_id = null;
      this.headerDetails.vendor_site_name = null;
      this.showInvoiceCommonModal = false;
    },
    isGrnMapped(invoiceLevel) {
      const grnAlreadyMappedMessage =
        'GRN already mapped for this Invoice. Kindly do the RTV or Correction in Oracle';
      if (invoiceLevel === 'GRN' && this.headerDetails.receipt_num) {
        alert(grnAlreadyMappedMessage);
        return;
      }
      this.showHideRejectModal(true);
    },
    // isGrnMapped(invoiceLevel) {
    //   if (invoiceLevel === 'GRN') {
    //     if (this.headerDetails.receipt_num) {
    //       alert(
    //         'GRN already mapped for this Invoice. Kindly do the RTV or Correction in Oracle'
    //       );
    //     } else {
    //       this.showHideRejectModal(true);
    //     }
    //   } else {
    //     this.showHideRejectModal(true);
    //   }
    // },
    openDocumentNewTab(docDtlId, fileName, authToken) {
      this.fileExtension = commonHelper.getFileExtension(fileName);
      if (this.fileExtension === 'xlsx') {
        alert('Not Allowed');
      } else {
        const payload = {
          docDetailId: docDtlId,
          token: authToken,
          fileExtension: this.fileExtension
        };
        this.loader = true;
        commonHelper.previewImgDocParty(payload);
        this.loader = false;
      }
    },
    validateVendorSite() {
      if (this.operatingUnit.value) {
        this.showHideInvoiceCommonModal(true, 'vendor_site', null, 'header');
      } else {
        alert('Please Enter Operating Unit');
      }
    },
    getVendorSiteDetails() {
      const payload = {
        _page: this.currentPageVendorSite - 1,
        _limit: this.perPageVendorSite,
        vendor_site_name: this.vendorSiteSearch,
        vendor_id: this.headerDetails.vendor_id,
        org_id: this.operatingUnit.value,
        invoice_date: this.headerDetails.inv_date,
        registration_number: this.headerDetails.third_party_gstn
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getVendorSiteDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.vendorSiteData = result;
            this.totalRowsVendorSite = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedVendorSite(item) {
      this.headerDetails.vendor_site_id = item.vendor_site_id;
      this.headerDetails.vendor_site_name = item.vendor_site_name;
      this.perPageVendorSite = commonHelper.perPageRecord;
      this.totalRowsVendorSite = null;
      this.currentPageVendorSite = 1;
      this.showInvoiceCommonModal = false;
    },
    endDateIndex(index) {
      this.specIndex = index;
      this.splitData = JSON.parse(JSON.stringify(this.splitData));
    },
    disabledDates(date) {
      return commonHelper.disabledDates(
        date,
        this.splitData[this.specIndex].from_date
      );
    },
    showHideUploadExcel(flag, type) {
      if (
        this.headerDetails.remarks &&
        this.operatingUnit.text &&
        this.headerDetails.vendor_site_id
      ) {
        this.downloadType = type;
        this.showUploadExcel = flag;
        setTimeout(() => {
          this.updateInvoiceDetailsManual();
        }, 0);
      } else {
        alert('Kindly enter mandatory fields.');
      }
    },
    removeCostCentreLine(item) {
      const payload = {
        cost_centre_id: item.inv_cost_centre_id,
        vendor_inv_dtl_id: item.vendor_invoice_dtl_id
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/removeCostCentreLine', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            alert('Cost Centre Line Delete Successfully.');
            this.getSplitLineDetailsById();
          } else {
            alert('Something went wrong.');
          }
        })
        .catch(() => {
          this.loader = false;
          alert(appStrings.autoFailedMsg);
        });
    },
    showHideLineDescriptionModal(flag, line_description, index) {
      this.showLineDescriptionModal = flag;
      if (line_description.length > 240) {
        this.lineDescription = line_description.substring(0, 240);
      } else {
        this.lineDescription = line_description;
      }
      this.specIndex = index;
    },
    saveLineDescription() {
      this.splitFinData[
        this.specIndex
      ].oracle_line_description = this.lineDescription;
      this.showLineDescriptionModal = false;
    },
    showHideSplitModalWithPo(flag) {
      this.showSplitModalWithPo = flag;
      if (!flag) {
        this.copyArrItems = [];
      }
    },
    newSplitFunction(item) {
      this.splitLineDataWithPo = item;
      this.splitLineDataWithPo.taxable_amount = parseFloat(
        this.splitLineDataWithPo.taxable_amount
      ).toFixed(2);
      this.showHideSplitModalWithPo(true);
      if (this.headerDetails.invoice_level === 'GRN') {
        this.getSplitLineWithPoById();
        setTimeout(() => {
          if (this.splitWithPoData.length < 1) {
            this.splitPeriodFromDate = item.period_from_date;
            this.splitPeriodToDate = item.period_to_date;
          }
        }, 500);
      }
      if (this.headerDetails.invoice_level === 'INVOICE') {
        this.getSplitLineWithPoById();
        this.getSplitLineFinWithPoDetailsById();
        setTimeout(() => {
          if (this.splitFinDataWithPo.length < 1) {
            this.finFromDateWithPo = item.period_from_date;
            this.finToDateWithPo = item.period_to_date;
          }
        }, 500);
      }
    },
    getSplitLineWithPoById() {
      const payload = {
        vendor_invoice_dtl_id: this.splitLineDataWithPo.vendor_inv_dtl_id
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getSplitLineWithPoById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.splitWithPoData = result.map(ele => {
              ele.amount = ele.distribution_amount;
              ele.period = ele.period_from;
              return ele;
            });
            if (this.splitWithPoData.length) {
              const total = this.splitWithPoData
                .filter(ele => ele.amount)
                .map(data => data.amount);
              const totalAmount = commonHelper.calculateTotal(total).toFixed(2);
              this.remainingAmtWithPo =
                this.splitLineDataWithPo.taxable_amount - totalAmount;
              this.remainingAmtWithPo = this.remainingAmtWithPo.toFixed(2);
            } else {
              this.splitWithPoData = [];
              this.remainingAmtWithPo = this.splitLineDataWithPo.taxable_amount;
              this.remainingAmtWithPo = this.remainingAmtWithPo.toFixed(2);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        })
        .finally(() => {
          this.isDateDisabledWithPo();
        });
    },
    dateClearFunctionWithPo() {
      this.splitPeriodToDate = null;
      this.splitWithPoData = [];
    },
    dateClearFunctionFinWithPo() {
      this.finToDateWithPo = null;
      this.splitFinDataWithPo = [];
    },
    calculateDateFunctionWithPo() {
      // not using function
      if (this.splitPeriodFromDate && this.splitPeriodToDate) {
        this.splitWithPoData = [];
        const periodTotalDays = this.getCustomDays(
          this.splitPeriodToDate,
          this.splitPeriodFromDate
        );
        const totalDays = this.getTotalDays(
          this.splitPeriodToDate,
          this.splitPeriodFromDate
        );
        for (let i = 0; i < periodTotalDays.length; i++) {
          const currentDate = new Date();
          const calAmt =
            (this.splitLineDataWithPo.taxable_amount * periodTotalDays[i]) /
            totalDays;
          this.splitWithPoData.push({
            period: this.periodFromDates[i]
              ? this.periodFromDates[i]
              : 'PREPAID',
            period_to: this.periodToDates[i]
              ? format(this.periodToDates[i], 'dd-MMM-yyyy')
              : null,
            days: periodTotalDays[i],
            amount: calAmt.toFixed(2),
            expense_month:
              // new Date(this.periodFromDates[i]).getMonth() + 1 <=
              // new Date().getMonth() + 1
              new Date(this.periodFromDates[i]) <= new Date()
                ? this.periodFromDates[i].split('-')[1].toUpperCase() +
                  '-' +
                  this.periodFromDates[i]
                    .split('-')[2]
                    .toString()
                    .slice(2)
                : 'PREPAID',
            // expense_month: this.periodFromDates[i]
            //   ? this.periodFromDates[i].split('-')[1].toUpperCase() +
            //     '-' +
            //     this.periodFromDates[i]
            //       .split('-')[2]
            //       .toString()
            //       .slice(2)
            //   : 'PREPAID',
            new_key:
              new Date(this.splitPeriodToDate) <=
              new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
                ? this.splitPeriodToDate.split('-')[1].toUpperCase() +
                    '-' +
                    this.splitPeriodToDate.split('-')[2] ==
                  this.periodFromDates[i].split('-')[1].toUpperCase() +
                    '-' +
                    this.periodFromDates[i].split('-')[2]
                  ? 'POSTPAID'
                  : null
                : null
          });
        }
        const splitArrCopy = JSON.parse(JSON.stringify(this.splitWithPoData));
        let result = [];
        // for (let i = 0; i < splitArrCopy.length; i++)
        for (let i = splitArrCopy.length; i > 0; i--) {
          const findIndex = splitArrCopy.findIndex(
            ele => ele.expense_month === 'PREPAID'
          );
          const findIndexPostpaid = splitArrCopy.findIndex(
            ele => ele.new_key === 'POSTPAID'
          );
          if (findIndex !== -1) {
            let prepaidExpenses = splitArrCopy.splice(0, findIndex + 1);
            result.push(prepaidExpenses);
            for (let i = 0; i < result.length; i++) {
              let sum = 0;
              result[i].filter(ele => {
                if (ele.expense_month !== 'PREPAID') {
                  sum += parseFloat(ele.amount);
                }
              });
              result[i].filter(data => {
                if (data.expense_month === 'PREPAID') {
                  data.amount = this.splitLineDataWithPo.taxable_amount - sum;
                  data.amount = data.amount.toFixed(2);
                }
              });
            }
          } else {
            let postpaidExpenses = splitArrCopy.splice(
              0,
              findIndexPostpaid + 1
            );
            result.push(postpaidExpenses);
            for (let i = 0; i < result.length; i++) {
              let sum = 0;
              result[i].filter(ele => {
                if (ele.new_key !== 'POSTPAID') {
                  sum += parseFloat(ele.amount);
                }
              });
              result[i].filter(data => {
                if (data.new_key === 'POSTPAID') {
                  data.amount = this.splitLineDataWithPo.taxable_amount - sum;
                  data.amount = data.amount.toFixed(2);
                }
              });
            }
          }
          let tempArr = [];
          for (let i = 0; i < result.length; i++) {
            result[i].forEach(ele => {
              tempArr.push(ele);
            });
          }
          if (findIndex !== -1) {
            this.splitWithPoData = tempArr;
          } else if (findIndexPostpaid !== -1) {
            this.splitWithPoData = tempArr;
          }
        }
        const filterAmt = this.splitWithPoData
          .filter(ele => ele.amount)
          .map(item => item.amount);
        if (filterAmt.length) {
          const totalFilterAmt =
            filterAmt.length > 1
              ? commonHelper.calculateTotal(filterAmt).toFixed(2)
              : parseFloat(filterAmt[0]).toFixed(2);
          this.remainingAmtWithPo =
            this.splitLineDataWithPo.taxable_amount - totalFilterAmt;
          this.remainingAmtWithPo = parseFloat(this.remainingAmtWithPo).toFixed(
            2
          );
        }
      } else {
        alert('Kindly Enter From Date and To Date');
      }
    },
    calculateDateFunctionFinWithPo() {
      if (this.finFromDateWithPo && this.finToDateWithPo) {
        this.splitFinDataWithPo = [];
        const periodTotalDays = this.getCustomDays(
          this.finToDateWithPo,
          this.finFromDateWithPo
        );
        const totalDays = this.getTotalDays(
          this.finToDateWithPo,
          this.finFromDateWithPo
        );
        for (let i = 0; i < periodTotalDays.length; i++) {
          const currentDate = new Date(this.headerDetails.gl_date);
          const calAmt =
            (this.splitLineDataWithPo.taxable_amount * periodTotalDays[i]) /
            totalDays;
          this.splitFinDataWithPo.push({
            // oracle_line_description:
            //   (this.splitLineDataWithPo.item_description
            //     ? this.splitLineDataWithPo.item_description
            //     : '') +
            //   ' | ' +
            //   (this.headerDetails.remarks ? this.headerDetails.remarks : '') +
            //   ' | ' +
            //   (this.splitWithPoData[i].expense_month
            //     ? this.splitWithPoData[i].expense_month
            //     : '') +
            //   ' | ' +
            //   (this.periodFromDates[i] ? this.periodFromDates[i] : '') +
            //   ' | ' +
            //   (this.periodToDates[i]
            //     ? format(this.periodToDates[i], 'dd-MMM-yyyy')
            //     : ''),
            period: this.periodFromDates[i]
              ? this.periodFromDates[i]
              : 'PREPAID',
            period_to: this.periodToDates[i]
              ? format(this.periodToDates[i], 'dd-MMM-yyyy')
              : null,
            days: periodTotalDays[i],
            amount: calAmt.toFixed(2),
            // expense_month: this.compareDate(
            //   this.periodFromDates[i],
            //   this.headerDetails.gl_date
            // ),
            expense_month:
              // new Date(this.periodFromDates[i]).getMonth() + 1 <=
              // new Date(this.headerDetails.gl_date).getMonth() + 1
              new Date(this.periodFromDates[i]) <=
              new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
                ? this.periodFromDates[i].split('-')[1].toUpperCase() +
                  '-' +
                  this.periodFromDates[i]
                    .split('-')[2]
                    .toString()
                    .slice(2)
                : 'PREPAID',
            // expense_month: this.periodFromDates[i]
            //   ? this.periodFromDates[i].split('-')[1].toUpperCase() +
            //     '-' +
            //     this.periodFromDates[i]
            //       .split('-')[2]
            //       .toString()
            //       .slice(2)
            //   : 'PREPAID',
            code_combination: this.splitLineDataWithPo.concatenated_segments,
            code_combination_id: this.splitLineDataWithPo.code_combination_id,
            new_key:
              new Date(this.finToDateWithPo) <=
              new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
                ? this.finToDateWithPo.split('-')[1].toUpperCase() +
                    '-' +
                    this.finToDateWithPo.split('-')[2] ==
                  this.periodFromDates[i].split('-')[1].toUpperCase() +
                    '-' +
                    this.periodFromDates[i].split('-')[2]
                  ? 'POSTPAID'
                  : null
                : null
          });
          this.splitFinDataWithPo.map(ele => {
            ele.oracle_line_description =
              (this.splitLineDataWithPo.item_description
                ? this.splitLineDataWithPo.item_description
                : '') +
              ' | ' +
              (this.headerDetails.remarks ? this.headerDetails.remarks : '') +
              ' | ' +
              (ele.expense_month ? ele.expense_month : '') +
              ' | ' +
              (this.periodFromDates[i] ? this.periodFromDates[i] : '') +
              ' | ' +
              (this.periodToDates[i]
                ? format(this.periodToDates[i], 'dd-MMM-yyyy')
                : '');
          });
        }
        //
        // for (let i = 0; i < this.splitWithPoData.length; i++) {
        //   for (let j = 0; j < periodTotalDays.length; j++) {
        //     const calAmt =
        //       (this.splitWithPoData[i].amount * periodTotalDays[j]) / totalDays;
        //     this.splitFinDataWithPo.push({
        //       oracle_line_description:
        //         (this.splitLineDataWithPo.item_description
        //           ? this.splitLineDataWithPo.item_description
        //           : '') +
        //         ' | ' +
        //         (this.headerDetails.remarks ? this.headerDetails.remarks : '') +
        //         ' | ' +
        //         (this.splitWithPoData[i].expense_month
        //           ? this.splitWithPoData[i].expense_month
        //           : '') +
        //         ' | ' +
        //         (this.finFromDateWithPo ? this.finFromDateWithPo : '') +
        //         ' | ' +
        //         (this.finToDateWithPo ? this.finToDateWithPo : ''),
        //       period: this.periodFromDates[j]
        //         ? this.periodFromDates[j]
        //         : 'PREPAID',
        //       period_to: this.periodToDates[j]
        //         ? format(this.periodToDates[j], 'dd-MMM-yyyy')
        //         : null,
        //       days: periodTotalDays[j],
        //       amount: calAmt.toFixed(2),
        //       expense_month: this.periodFromDates[j]
        //         ? this.periodFromDates[j].split('-')[1].toUpperCase() +
        //           '-' +
        //           this.periodFromDates[j]
        //             .split('-')[2]
        //             .toString()
        //             .slice(2)
        //         : 'PREPAID',
        //       code_combination: this.splitLineDataWithPo.concatenated_segments,
        //       code_combination_id: this.splitLineDataWithPo.code_combination_id,
        //       new_key:
        //         new Date(this.finToDateWithPo) < new Date()
        //           ? this.finToDateWithPo.split('-')[1].toUpperCase() +
        //               '-' +
        //               this.finToDateWithPo.split('-')[2] ==
        //             this.periodFromDates[j].split('-')[1].toUpperCase() +
        //               '-' +
        //               this.periodFromDates[j].split('-')[2]
        //             ? 'POSTPAID'
        //             : null
        //           : null
        //       // expense_month: this.splitData[i].expense_month
        //     });
        //   }
        // }
        //
        const finArrCopy = JSON.parse(JSON.stringify(this.splitFinDataWithPo));
        let result = [];
        // for (let i = 0; i < finArrCopy.length; i++)
        for (let i = finArrCopy.length; i > 0; i--) {
          const findIndex = finArrCopy.findIndex(
            ele => ele.expense_month === 'PREPAID'
          );
          const findIndexPostpaid = finArrCopy.findIndex(
            ele => ele.new_key === 'POSTPAID'
          );
          if (findIndex !== -1) {
            let prepaidExpenses = finArrCopy.splice(0, findIndex + 1);
            result.push(prepaidExpenses);
            for (let i = 0; i < result.length; i++) {
              let sum = 0;
              result[i].filter(ele => {
                if (ele.expense_month !== 'PREPAID') {
                  sum += parseFloat(ele.amount);
                }
              });
              result[i].filter(data => {
                if (data.expense_month === 'PREPAID') {
                  data.amount = this.splitLineDataWithPo.taxable_amount - sum;
                  data.amount = data.amount.toFixed(2);
                }
              });
            }
          } else {
            let postpaidExpenses = finArrCopy.splice(0, findIndexPostpaid + 1);
            result.push(postpaidExpenses);
            for (let i = 0; i < result.length; i++) {
              let sum = 0;
              result[i].filter(ele => {
                if (ele.new_key !== 'POSTPAID') {
                  sum += parseFloat(ele.amount);
                }
              });
              result[i].filter(data => {
                if (data.new_key === 'POSTPAID') {
                  data.amount = this.splitLineDataWithPo.taxable_amount - sum;
                  data.amount = data.amount.toFixed(2);
                }
              });
            }
          }
          let tempArr = [];
          for (let i = 0; i < result.length; i++) {
            result[i].forEach(ele => {
              tempArr.push(ele);
            });
          }
          if (findIndex !== -1) {
            this.splitFinDataWithPo = tempArr;
          } else if (findIndexPostpaid !== -1) {
            this.splitFinDataWithPo = tempArr;
          }
        }
        const filterAmt = this.splitFinDataWithPo
          .filter(ele => ele.amount)
          .map(item => item.amount);
        if (filterAmt.length) {
          const totalFilterAmt =
            filterAmt.length > 1
              ? commonHelper.calculateTotal(filterAmt).toFixed(2)
              : parseFloat(filterAmt[0]).toFixed(2);
          this.remainingFinAmtWithPo =
            this.splitLineDataWithPo.taxable_amount - totalFilterAmt;
          this.remainingFinAmtWithPo = parseFloat(
            this.remainingFinAmtWithPo
          ).toFixed(2);
        }
      } else {
        // if (this.finFromDateWithPo == null && this.finToDateWithPo == null) {
        //   const tempArr = this.splitWithPoData.map(item => ({
        //     oracle_line_description:
        //       (this.splitLineDataWithPo.item_description
        //         ? this.splitLineDataWithPo.item_description
        //         : '') +
        //       ' | ' +
        //       (this.headerDetails.remarks ? this.headerDetails.remarks : '') +
        //       ' | ' +
        //       (item.expense_month ? item.expense_month : '') +
        //       ' | ' +
        //       (item.period ? item.period : '') +
        //       ' | ' +
        //       (item.period_to ? item.period_to : ''),
        //     period: item.period,
        //     period_to: item.period_to,
        //     days: item.days,
        //     amount: item.amount,
        //     expense_month: item.expense_month,
        //     code_combination: this.splitLineDataWithPo.concatenated_segments,
        //     code_combination_id: this.splitLineDataWithPo.code_combination_id
        //   }));
        //   this.splitFinDataWithPo = tempArr;
        //   this.remainingFinAmtWithPo = parseFloat(0).toFixed(2);
        // } else {
        alert('Kindly Enter From Date and To Date');
        // }
      }
    },
    calculateAmountFinanceWithpo(item) {
      const totalLineAmount = this.splitWithPoData
        .filter(ele => ele.amount)
        .map(data => data.amount);
      if (totalLineAmount.length) {
        const finalAmount =
          totalLineAmount.length > 1
            ? commonHelper.calculateTotal(totalLineAmount).toFixed(2)
            : parseFloat(totalLineAmount[0]).toFixed(2);
        if (finalAmount > +this.splitLineDataWithPo.taxable_amount) {
          alert('Amount cannot be greater than Remaining Amount');
          item.amount = null;
          this.calculateAmountFinanceWithpo(item);
        } else {
          this.remainingAmtWithPo =
            +this.splitLineDataWithPo.taxable_amount - finalAmount;
          this.remainingAmtWithPo = this.remainingAmtWithPo.toFixed(2);
        }
      } else {
        this.remainingAmtWithPo = this.splitLineDataWithPo.taxable_amount;
      }
    },
    isDateDisabled() {
      const isDistributionSaved = this.splitFinData.some(
        ele => ele.inv_distribution_dtl_id
      );
      if (isDistributionSaved) {
        this.dateDisabledFlag = true;
      } else {
        this.dateDisabledFlag = false;
      }
    },
    isDateDisabledWithPo() {
      const isCostCentreSaved = this.splitWithPoData.some(
        ele => ele.inv_distribution_dtl_id
      );
      if (isCostCentreSaved) {
        this.dateDisabledFlagWithPo = true;
      } else {
        this.dateDisabledFlagWithPo = false;
      }
    },
    isDateDisabledFinWithPo() {
      const isCostCentreSaved = this.splitFinDataWithPo.some(
        ele => ele.inv_distribution_dtl_id
      );
      if (isCostCentreSaved) {
        this.dateDisabledFinFlagWithPo = true;
      } else {
        this.dateDisabledFinFlagWithPo = false;
      }
    },
    showHideDistributionModal(flag) {
      this.showDistributionModal = flag;
      if (flag) {
        this.getDistributionDetails();
      }
      if (!flag) {
        this.distributionSearch = null;
        this.currentPageDistribution = 1;
        this.perPageDistribution = commonHelper.perPageRecord;
        this.pageOptionsDistribution = commonHelper.getPageOption();
        this.totalRowsDistribution = null;
      }
    },
    getDistributionDetails() {
      const payload = {
        _page: this.currentPageDistribution - 1,
        _limit: this.perPageDistribution,
        distribution_set: this.distributionSearch
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getDistributionDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.distributionData = result;
            this.totalRowsDistribution = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelectedDistribution(item) {
      if (item.distribution_set_id) {
        this.distribution.text = item.distribution_set_name;
        this.distribution.value = item.distribution_set_id;
        this.getDistributionSetDetails(item);
      }
      this.showHideDistributionModal(false);
    },
    getDistributionSetDetails(item) {
      const payload = { distribution_set_id: item.distribution_set_id };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getDistributionSetDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.splitData = result.map(ele => {
              ele.state_code = ele.segment4;
              ele.cost_centre = ele.segment5;
              ele.amount =
                (this.splitLineData.taxable_amount * ele.percent_distribution) /
                100;
              ele.amount = parseFloat(ele.amount).toFixed(2);
              return ele;
            });
            const totalLength = this.splitData.length;
            if (totalLength > 1) {
              const filterAmt = this.splitData
                .slice(0, -1)
                .filter(ele => ele.amount)
                .map(data => data.amount);
              const totalAmt =
                filterAmt.length > 1
                  ? commonHelper.calculateTotal(filterAmt).toFixed(2)
                  : parseFloat(filterAmt[0]).toFixed(2);
              const transferAmt = this.splitLineData.taxable_amount - totalAmt;
              this.splitData[totalLength - 1].amount = parseFloat(
                transferAmt
              ).toFixed(2);
            }
            this.remainingAmount = parseFloat(0).toFixed(2);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    isDistributionDetailsSaved() {
      const isDistributionSetSaved = this.splitData.some(
        ele => ele.inv_cost_centre_id
      );
      if (isDistributionSetSaved) {
        this.distributionSetFlag = true;
      } else {
        this.distributionSetFlag = false;
      }
    },
    deleteCostCentre(type) {
      const promt = confirm(
        'Are you sure, you want to delete this cost centre?'
      );
      if (promt) {
        const payload = {
          vendor_inv_dtl_id:
            type === 'without_po'
              ? this.splitLineData.vendor_inv_dtl_id
              : this.splitLineDataWithPo.vendor_inv_dtl_id,
          type: type
        };
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/deleteCostCentre', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              alert('Record Deleted Successfully');
              if (type === 'without_po') {
                this.getSplitLineDetailsById();
              } else {
                this.getSplitLineWithPoById();
              }
            } else {
              alert(resp.response.data.message);
            }
          })
          .catch(() => {
            this.loader = false;
            alert(appStrings.autoFailedMsg);
          });
      }
    },
    downloadCostCentreDetails() {
      const payload = {
        vendor_inv_id: this.vendorInvId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/downloadCostCentreDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            const customDownloadData = result.map(obj => ({
              manual_invoice_line_id: obj.manual_invoice_line_id,
              oracle_line_description: obj.oracle_line_description,
              period_from: obj.period_from,
              period_to: obj.period_to,
              days: obj.days,
              cost_centre: obj.cost_centre,
              cost_centre_amount: obj.cost_centre_amount,
              distribution_amount: obj.distribution_amount,
              expense_month: obj.expense_month,
              code_combination: obj.code_combination
            }));
            if (customDownloadData.length) {
              commonHelper.downloadExcel(
                customDownloadData,
                'cost-centre-details'
              );
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    deleteDistributionDetails(type, item) {
      const payload = {
        type: type,
        vendor_inv_dtl_id: item.vendor_inv_dtl_id
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/deleteDistributionDetails', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            alert('Distribution Line Delete Successfully.');
            if (type === 'without_po') {
              this.getSplitLineFinDetailsById();
            } else if (type === 'with_po') {
              this.getSplitLineFinWithPoDetailsById();
            }
          } else {
            alert('Something went wrong.');
          }
        })
        .catch(() => {
          this.loader = false;
          alert(appStrings.autoFailedMsg);
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    clearTimeout(this.setTimeVset);
    document.removeEventListener('keydown', this.handleKeyDown);
  }
};
